import {
  numberInteger,
  numberDecimals,
  numberTwoDecimals,
  numberFourDecimals,
  positiveInteger,
  userRegular,
  userNameRegular,
  emailRegular,
  telephone,
  userPasswordRegular,
  userSpecific,
  numberThousandthSeparator,
  floatInteger,
  stringIp,
  numberSwitch
} from './regular-expression'
import moment from 'moment'

const ruleCommon = (text, mode = '输入', whitespace = true) => {
  if (whitespace) return [{ required: true, whitespace: true, message: `请${mode}${text}` }]
  else return [{ required: true, message: `请${mode}${text}` }]
}
const ruleSelect = (text, mode = "选择", whitespace = true) => {
  if (whitespace)
    return [{ required: true, trigger: 'blur', message: `请选择${text}` }];
  else return [{ required: true, message: `请${mode}${text}` }];
};

// 校验一位小数
const ruleNumberDecimals = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberDecimals, message: `请输入正确格式的${text}` }
  ]
}

// 校验两位小数
const ruleNumberTwoDec = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberTwoDecimals, message: `请输入正确格式的${text}` }
  ]
}
// 校验四位小数
const ruleNumberFourDec = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberFourDecimals, message: `请输入正确格式的${text}` }
  ]
}
// 校验正整数
const ruleNumberInt = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: positiveInteger, message: `请输入正确格式的${text}` }
  ]
}
// 校验整数
const ruleNumberInteger = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberInteger, message: `请输入正确格式的${text}` }
  ]
}
// 校验浮动整数
const ruleFloatInteger = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: floatInteger, message: `请输入正确格式的${text}` }
  ]
}
// 校验有大小限制的数字
const ruleNumberLimit = (text, type = 2, min = 0, max = null, required = true, door = false) => {
  if (door) {
    return [
      { required: false },
      () => ({
        validator() {
          return Promise.resolve()
        }
      })
    ]
  }
  return [
    { required: required, message: `请输入${text}` },
    () => ({
      validator(rule, value) {
        if (!value) return Promise.reject()
        switch (type) {
          case 0:
            if (!positiveInteger.test(value)) return Promise.reject(`请输入正确的${text}`)
            break
          case 1:
            if (!numberDecimals.test(value)) return Promise.reject(`请输入正确的${text}`)
            break
          case 2:
            if (!numberTwoDecimals.test(value)) return Promise.reject(`请输入正确的${text}`)
            break
          case 4:
            if (!numberFourDecimals.test(value)) return Promise.reject(`请输入正确的${text}`)
            break
          default:
        }
        let temp_value = Number(value)
        if (temp_value < min) return Promise.reject(`请输入正确的${text}`)
        else if (max && temp_value > max) return Promise.reject(`请输入正确的${text}`)
        return Promise.resolve()
      }
    })
  ]
}
// 选择日期或长期
const ruleDateRangeOrLongTerm = (form, type, depType, text) => {
  let longTerm = form.getFieldValue(depType)
  return [
    { required: !longTerm, message: `请选择${text}` },
    ({ getFieldValue, setFieldsValue }) => ({
      validator() {
        let rangeData = getFieldValue(type)
        if (longTerm) {
          setFieldsValue({
            [type]: null
          })
          return Promise.resolve()
        } else if (rangeData) {
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      }
    })
  ]
}
// 结束日期依赖开始日期
const ruleConfirmEndDate = (start, status, text) => {
  return [
    { required: status, message: `请选择${text}` },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        let startDate = getFieldValue(start) || 0
        let startTimeStamp = new Date(moment(startDate).format('YYYY-MM-DD')).getTime()
        console.log(startDate, startTimeStamp)
        let endTimeStamp = new Date(moment(value).format('YYYY-MM-DD')).getTime()
        if (!value || startTimeStamp < endTimeStamp) {
          return Promise.resolve()
        }
        return Promise.reject('结束时间不能小于等于开始时间')
      }
    })
  ]
}
//不得选择过去的时间
const rulePastTimes = (status, text) => {
  return [
    { required: status, message: `请选择${text}` },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        let sss = new Date()
        console.log(sss > new Date(value))
        if (sss > new Date(value)) {
          return Promise.reject('不得选择过去时间')
        }
        return Promise.resolve()
      }
    })
  ]
}
// 校验用户名
const ruleUser = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: userRegular, message: `请输入正确格式的${text}` }
  ]
}
//密码
const ruleUserPassword = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: userPasswordRegular, message: `6~18位字母+数字的组合，字母区分大小写` }
  ]
}
// 校验中文字符
const ruleName = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: userNameRegular, message: `请输入正确格式的${text}` }
  ]
}
// 校验邮箱
const ruleEmail = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: emailRegular, message: `请输入正确格式的${text}` }
  ]
}
// 验证手机号
const ruleTelephone = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: telephone, message: `请输入正确格式的${text}` }
  ]
}

// 8-20位数字、字母或标点符号，不区分大小写
const ruleUserSpecific = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: userSpecific, message: `请输入正确格式的${text}` }
  ]
}
// 保留小数点后两位 用千位分割符
const ruleThousandthSeparator = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberThousandthSeparator, message: `请输入正确格式的${text}` }
  ]
}

// 校验两位小数 对比金额大小
const ruleContrastMoney = (text, receiveAmount, paymentApNoList = [], required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberTwoDecimals, message: `请输入正确格式的${text}` },
    ({ getFieldValue, setFieldsValue }) => ({
      validator(rule, value) {
        let useableAmount = '';
        if (paymentApNoList && paymentApNoList.length !== 0) {
          useableAmount = paymentApNoList.find((item) => {
            return (item.paymentApNo + '') === getFieldValue('paymentApNo') + ''
          }).useableAmount
        }
        if (useableAmount === '' || useableAmount === null || useableAmount === undefined) {
          return Promise.resolve()
        }
        if ((getFieldValue('receiveAmount') - 0) > (useableAmount - 0)) {
          return Promise.reject('输入金额不能大于关联付款凭可登记额度')
        }
        return Promise.resolve()
      }
    })
  ]
}

// 相同的密码
const ruleSamePassword = (text, first, mode = '输入') => {
  return [
    { required: true, whitespace: true, message: `请${mode}${text}` },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue([first]) === value) {
          return Promise.resolve();
        }
        return Promise.reject('请输入相同的密码');
      },
    }),
  ]
}

// 校验是否正确的ip地址
const ruleIp = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: stringIp, message: `请输入正确格式的${text}` }
  ]
}

const ruleSwtich = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberSwitch, message: `${text}必须为0[有效]或1[失效]` }
  ]
}

export default {
  ruleCommon,
  ruleSelect,
  ruleNumberDecimals,
  ruleNumberTwoDec,
  ruleNumberFourDec,
  ruleNumberInt,
  ruleNumberInteger,
  ruleNumberLimit,
  ruleDateRangeOrLongTerm,
  ruleConfirmEndDate,
  ruleName,
  ruleUser,
  ruleEmail,
  ruleTelephone,
  ruleUserPassword,
  ruleUserSpecific,
  ruleThousandthSeparator,
  ruleFloatInteger,
  ruleContrastMoney,
  ruleSamePassword,
  ruleIp,
  ruleSwtich,
  rulePastTimes
}
