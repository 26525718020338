import React, { memo, useState, useEffect, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
// antd
import { Menu as AntdMenu } from "antd";
import { ExperimentOutlined } from "@ant-design/icons";
// redux
import { useSelector, useDispatch } from "react-redux";
import { updateAuthority } from "@/store/actions";
// utils
import { Utils, Storage } from "@/utils";
import "./Menu.less";

import systemModel from "@/model/Global/menuModel";

const Menu = ({ location, loginType }) => {
  const navTapsState = useSelector(state => state.navTaps);
  const [menuItems, setMenuItems] = useState([]); // 菜单元素列表
  const [navList, setNavList] = useState({}); // 菜单数据列表

  const dispatch = useDispatch();

  // 生成菜单树
  const getMenus = useCallback(
    (menuArray, isSiderFold = true, parentPath = "/") => {
      return menuArray.map(item => {
        /**
         * item.key item.menuUrl
         * item.children item.children
         * item.icon item.menuIcon
         * item.name item.menuName
         * item.type item.menuType 菜单类型（M目录 C菜单 F按钮）
         */
        // if (!['M', 'C'].includes(item.menuType)) { return false }
        const linkTo = parentPath + item.menuUrl;
        // 生成 列表 (路由 -> 名称)
        navList[linkTo] = item.menuName;
        // item.menuType === 'M' 假定 菜单只有两级 M和C，C之下是按钮不参与循环
        if (item.children && item.children.length && item.menuType === "M") {
          setNavList(navList);
          return (
            <AntdMenu.SubMenu
              key={`${linkTo}_submenu`}
              icon={item.menuIcon ? <ExperimentOutlined /> : ""}
              title={item.menuName.replace("保险端-", "")}
            >
              {getMenus(item.children, isSiderFold, `${linkTo}/`)}
            </AntdMenu.SubMenu>
          );
        }
        return (
          <AntdMenu.Item key={linkTo}>
            <Link to={linkTo}>{item.menuName.replace("保险端-", "")}</Link>
          </AntdMenu.Item>
        );
      });
    },
    [navList]
  );
  console.log(loginType);
  useEffect(() => {
    if (loginType !== "") {
      systemModel.getUserMenuList().then(res => {
        if (res.datas) {
          let loginTypeStorage = Storage.getStorage("loginType");
          let enterpriseMenu = res.datas.slice(0);
          enterpriseMenu = enterpriseMenu.map(item => {
            let isInsurance = item.menuUrl.indexOf("insurance/") !== -1;
            // let isUser = item.menuUrl.indexOf('user/') !== -1
            let isM = item.menuType === "M";
            if (isM && !isInsurance) {
              let prefix = "";
              switch (loginTypeStorage) {
                case "6":
                  // User
                  prefix = "user/";

                  break;
                case "2":
                case "3":
                  // Insurance
                  prefix = "insurance/";

                  break;

                default:
                  break;
              }
              return {
                ...item,
                menuUrl: prefix + item.menuUrl
              };
            }
            return item;
          });
          console.log(enterpriseMenu, "enterpriseMenu", loginType);
          if(loginType === '3'){
            // menu 数据操作
            // enterpriseMenu.splice(res.datas.length-1,1);
            for(let i= 0; i<enterpriseMenu.length; i++){
              console.log(enterpriseMenu[i]);
              if(enterpriseMenu[i].menuName === '理赔管理'){
                delete
                enterpriseMenu[i]
              }
              // else if(enterpriseMenu[i].menuName === '卡号管理'){
              //   delete
              //   enterpriseMenu[i].children.splice(enterpriseMenu[i].children.length - 1, 1)
              // }
            }
            setMenuItems(getMenus(enterpriseMenu))
          }else{
            setMenuItems(getMenus(enterpriseMenu))
          }

          let authority = Utils.transformAuthority(enterpriseMenu);
          dispatch(updateAuthority(authority));
        }
      });
    }
  }, [dispatch, getMenus, loginType]);

  // 菜单点击事件
  const handleClickNavMenu = props => {
    let key = props.key;
    let title = navList[props.key];

    let findedNavTapsIndex = navTapsState.findIndex(item => item.key === key);
    if (findedNavTapsIndex === -1) {
      let newNavTap = { key, title, isShow: true, reload: 0 };
      if (!newNavTap) return;
    } else {
    }
  };

  return (
    <AntdMenu
      key="1"
      mode="inline"
      selectedKeys={[location.pathname]}
      onClick={handleClickNavMenu}
      className="menuList"
    >
      {menuItems}
    </AntdMenu>
  );
};

export default withRouter(memo(Menu));
