import axios from "axios";
import { Utils, Storage, LoadingRef } from "@/utils";

const TIME_OUT = 10 * 60 * 1000

// axios 配置
axios.defaults.timeout = TIME_OUT; //响应时间
// 当实例创建时设置默认配置
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log(process.env.REACT_APP_BASE_URL, "process.env.REACT_APP_BASE_URL");
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  console.log(process.env.REACT_APP_BASE_URL, "process.env.REACT_APP_BASE_URL");
}
// axios.defaults.headers.post['Content-Type'] = 'application/json'; //配置请求头
// axios.defaults.headers.get['Content-Type'] = 'application/json'; //配置请求头
// axios.defaults.headers.delete['Content-Type'] = 'application/json'; //配置请求头
// axios.defaults.headers.put['Content-Type'] = 'application/json'; //配置请求头
// axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('Authorization')

// http request 拦截器
axios.interceptors.request.use(
  config => {
    // 下拉框查询不触发loading弹窗
    if ((config.data && config.data.noLoadingRef === 'yes')) {
      delete config.data.noLoadingRef
    } else if ((config.params && config.params.noLoadingRef === 'yes')) {
      delete config.params.noLoadingRef
    } else {
      LoadingRef.handleOpenByUrl(config.url)
    }
    config.headers.Authorization = Storage.getStorage('Authorization') || ''
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// http response 拦截器:返回状态判断（添加响应拦截器）
axios.interceptors.response.use(
  response => {
    if (response.config && response.config.url) {
      LoadingRef.handleCloseByUrl(response.config.url)
    }
    const res = response.data;
    if (res.success) return res;
    if (response.request.responseType === "blob" && response.status === 200)
      return response;
    Utils.errorMsg({ title: res.message || "未知错误" });
    return Promise.reject(res.message || "未知错误");
  },
  error => {
    if (error.config && error.config.url) {
      LoadingRef.handleCloseByUrl(error.config.url)
    }
    let response = error.response;
    if (!response) {
      return Promise.reject("未知错误");
    }
    let err = response.data;
    if (response.status === 401) {
      Utils.errorMsg({
        title: `${err.message || "未知错误"}, 请重新登录！`,
        content: "确认后跳转登录页面",
        onOk: () => {
          window.location.href = "/login";
        }
      });
    }
    if (response.status === 504) Utils.errorMsg({ title: "请求超时" });
    return Promise.reject(error);
  }
);

export default {
  axios
};
