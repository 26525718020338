import _ from "lodash";
import {
  // ExperimentOutlined,
  BookOutlined,
  ToolOutlined
} from "@ant-design/icons";

const MENU = [
  {
    id: _.uniqueId(),
    menuUrl: "insurance/card-manage",
    menuName: "卡号管理",
    menuIcon: BookOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: "card-cardQuery",
        menuName: "卡号查询",
        menuType: "C"
      },
      {
        id: _.uniqueId(),
        menuUrl: "card-log",
        menuName: "卡操作日志",
        menuType: "C"
      },
      // {
      //   id: _.uniqueId(),
      //   menuUrl: "card-operating-log",
      //   menuName: "卡操作日志",
      //   menuType: "C"
      // }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: "insurance/claims-center",
    menuName: "理赔管理",
    menuIcon: BookOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: "claim-claim",
        menuName: "理赔列表",
        menuType: "C"
      },
      {
        id: _.uniqueId(),
        menuUrl: "claim-log",
        menuName: "理赔监控日志",
        menuType: "C"
      },
      {
        id: _.uniqueId(),
        menuUrl: "claim-batchClaim",
        menuName: "批量理赔管理",
        menuType: "C"
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: "insurance/group-order-manage",
    menuName: "团单管理",
    menuIcon: ToolOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: "groupPolicy-groupPolicyStrategy",
        menuName: "团单策略查询",
        menuType: "C"
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: "insurance/statistical-query",
    menuName: "统计查询",
    menuIcon: BookOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: "statistics-openCardCount",
        menuName: "开卡统计",
        menuType: "C"
      },
      {
        id: _.uniqueId(),
        menuUrl: "statistics-cardSaleCount",
        menuName: "卡消费统计",
        menuType: "C"
      },
      {
        id: _.uniqueId(),
        menuUrl: "statistics-companySaleCount",
        menuName: "投保单位消费统计",
        menuType: "C"
      },
      {
        id: _.uniqueId(),
        menuUrl: "statistics-daySaleCount",
        menuName: "日消费统计",
        menuType: "C"
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: "insurance/order-manage",
    menuName: "订单管理",
    menuIcon: ToolOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: "order-orderQuery",
        menuName: "订单查询",
        menuType: "C"
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: "insurance/company-system",
    menuName: "系统管理",
    menuIcon: ToolOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: "company-system-updatePassword",
        menuName: "修改密码",
        menuType: "C"
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: "insurance/company-account",
    menuName: "财务对账文件",
    menuIcon: BookOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: "company-account-insuranceCompany",
        menuName: "资金方结算列表",
        menuType: "C"
      },
      {
        id: _.uniqueId(),
        menuUrl: "company-account-qjPayment",
        menuName: "统一结算列表",
        menuType: "C"
      }
    ]
  }
];

export default MENU;
