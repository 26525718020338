import Model from '../index'
import { Utils } from '@/utils';

class Login extends Model {
  constructor() {
    super()
    this.requestServerPrefix = '/system'
  }
  // 获取登录验证图片
  getCheckImg(options = {}) {
    options.url = '/web/system/captchaImage'
    return this.get(options)
  }
  // 登录接口
  userLogin(options = {}) {
    options.url = '/web/system/login'
    return this.post(options)
  }
  // 登出
  userLogout(options = {}) {
    options.url = '/web/system/logout'
    return this.get(options)
  }
  // 用户登录是否过期
  userLoginExpire(options = {}) {
    options.url = '/web/system/userLoginExpire'
    return this.get(options)
  }
  // 登录信息
  queryUserDetail(options = {}) {
    options.data = Utils.transJsonToForm(options.data)
    options.url = '/web/user/queryUserDetail'
    return this.post(options)
  }
  // 获取最新5条公告
  queryTop5Notice(options = {}) {
    options.data = Utils.transJsonToForm(options.data)
    options.url = '/web/notice/queryTop5Notice'
    return this.post(options)
  }
  // 修改密码
  updatePassword(options = {}) {
    options.url = '/web/user/updatePassword'
    return this.post(options)
  }
  // 获取用户上次登录信息
  queryLastLoginInfo(options = {}) {
    options.url = '/web/user/queryLastLoginInfo'
    return this.get(options)
  }
  // 首页轮播图
  queryAdByType(options = {}) {
    options.data = Utils.transJsonToForm(options.data)
    options.url = '/web/ad/queryAdByType'
    return this.post(options)
  }
  // 获取验证码
  sendSmsCaptcha(options = {}) {
    // options.data = Utils.transJsonToForm(options.data)
    options.url = '/web/sms/sendSmsCaptchaCode'
    return this.post(options)
  }

}

const loginModel = new Login()
export default loginModel
