import MENU from './menu'

import Storage from './storage'
import Utils from './utils'
import Cookie from './cookie'
import Rules from './rules'
import Request from './request'
import Global from './global'
import History from './history'
import Store from './store'
import LoadingRef from './loadingRef'

const PAGE_SIZE = 10

const NAV_TAPS_INITIAL = [{ title: '首页', key: '/home' }]
const NAV_CACHE_INITIAL = [{ key: '/home', cacheDataStr: '' }]

// 弹窗表单公共设置
const OPTIONS_FORM_MODAL = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  labelAlign: 'left'
}

// 搜索栏表单公共设置
const OPTIONS_FORM_SEARCH = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 11 },
    lg: { span: 9 },
    xl: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 13 },
    lg: { span: 15 },
    xl: { span: 17 },
  },
  labelAlign: 'left'
};

// 表格公共设置
const OPTIONS_TABLE = {
  pagination: false,
  bordered: true,
  // scroll: { x: 'max-content' }
}

// 子表格公共设置
const OPTIONS_CHILDER_TABLE ={
  pagination: false,
  bordered: true,
  showHeader:false
}

// 弹窗公共设置
const OPTIONS_MODAL = {
  width: '900px',
  footer: null,
  wrapClassName: 'modal__box-wrap',
  className: 'modal__box',
  style: {
    top: '10%'
  },
  keyboard: false,
  maskClosable: false,
  getContainer: false
}

const OPTIONS_MODAL_MaX = {
  width: '1100px',
  footer: null,
  wrapClassName: 'modal__box-wrap',
  className: 'modal__box',
  style: {
    top: '10%'
  },
  keyboard: false,
  maskClosable: false,
  getContainer: false
}

// 跳窗公共设置-小
const OPTIONS_MODAL_MINI = {
  width: '500px',
  footer: null,
  wrapClassName: 'modal__box-wrap',
  className: 'modal__box',
  style: {
    top: '10%'
  },
  keyboard: false,
  maskClosable: false,
  getContainer: false
}

// 下拉框动态搜索设置
const OPTIONS_SELECT_SEARCH = {
  showSearch: true,
  defaultActiveFirstOption: false,
  showArrow: false,
  filterOption: false,
  notFoundContent: null
}

// 输入框长度限制
const OPTIONS_INPUT_LENGTH = {
  maxLength: 50
}

// 富文本框长度限制
const OPTIONS_TEXTAREA_LENGTH = {
  maxLength: 300
}

// 表格按钮公共设置
const OPTIONS_TABLE_BTN = {
  size: 'small',
  type: 'primary'
}

export {
  MENU, // 左侧导航
  NAV_TAPS_INITIAL, // 顶部导航
  NAV_CACHE_INITIAL, // 导航页面缓存数据
  PAGE_SIZE, // 每页数据大小
  OPTIONS_FORM_MODAL, // 表单属性 - 弹窗
  // OPTIONS_FORM_MODAL_12, // 表单属性 - 12
  // OPTIONS_FORM_MODAL_24, // 表单属性 - 24
  OPTIONS_FORM_SEARCH, // 表单属性 - 搜索
  OPTIONS_TABLE, // 表格属性
  OPTIONS_CHILDER_TABLE, // 子表格属性
  OPTIONS_TABLE_BTN, // 表格按钮属性
  OPTIONS_MODAL, // 弹窗属性
  OPTIONS_MODAL_MaX,
  OPTIONS_MODAL_MINI, // 弹窗属性-mini
  OPTIONS_SELECT_SEARCH, // 下拉框动态搜索
  OPTIONS_INPUT_LENGTH, //输入框最大长度
  OPTIONS_TEXTAREA_LENGTH, //富文本框最大长度

  Storage, // 本地缓存操作 （localStorage）

  Utils, // 扩展方法

  Cookie,                    // cookie 方法

  Rules,         //校验

  Request,        // 请求

  Global,          // 全局请求（字典相关、下拉选择等）
  History,           // 路由方法
  Store,             // redux 转 localStorage 相关方法
  LoadingRef         // loadingRef
}
