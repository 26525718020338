// 兼容IE
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "core-js/es/map";
import "core-js/es/set";
import "regenerator-runtime/runtime";

import React from 'react';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import ReactDOM from 'react-dom';
import './themes/index.less';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
// redux
import { Provider } from 'react-redux';
import store from '@/store/index';

// import { enableES5 } from "immer";

moment.locale('zh-cn');
// enableES5();

// event发布订阅
if (typeof window.ee === 'undefined') {
  const EventEmitter = require('events');
  window.ee = new EventEmitter();
}

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
        <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
