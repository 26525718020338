import React from 'react';
import { message, notification, Modal } from 'antd'; //引入全局提示
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import _ from 'lodash'
const { confirm } = Modal;

message.config({
  top: 50,  //消息距离顶部位置
  maxCount: 1, // 最大显示数
  duration: 2
})

notification.config({
  // placement: 'bottomRight',
  // bottom: 50,
  duration: 5,
  // rtl: true,
})

const messageTransform = (paramInfo) => {
  let paramIsString = typeof paramInfo === 'string' || paramInfo.$$typeof;
  let paramObj = {
    title: '',
    content: '',
    onOk: undefined,
    okButtonProps: {}
  }

  if (paramIsString) {
    paramObj.content = paramInfo
  } else {
    paramObj.title = paramInfo.title || ''
    paramObj.content = paramInfo.content || ''
    paramObj.onOk = paramInfo.onOk || undefined
    paramObj.okButtonProps = paramInfo.okButtonProps || {}
  }
  return paramObj
}

/**
 * 全局提示 - 成功
 * @param {String} text
 */
export const successMsg = (text) => {
  message.success(text)
}
/**
 * 全局提示 - 警告
 * @param {String} text
 */
export const warnMsg = (paramInfo) => {
  let paramObj = messageTransform(paramInfo);
  Modal.warning({
    title: paramObj.title || '警告',
    content: paramObj.content,
    onOk() {
      if (!paramObj.onOk) return false
      paramObj.onOk()
    },
    okButtonProps: paramObj.okButtonProps
  });
}
/**
 * 全局提示 - 失败
 * @param {
 *  title String
 *  content String
 *  onOk method
 * }
 */
export const errorMsg = (paramInfo) => {
  let paramObj = messageTransform(paramInfo);
  Modal.error({
    title: paramObj.title,
    content: paramObj.content,
    onOk() {
      if (!paramObj.onOk) return false
      paramObj.onOk()
    },
    okButtonProps: paramObj.okButtonProps
  });
}
/**
 * 全局提示 - 信息
 * @param {
 *  title String
 *  content String
 *  onOk method
 * }
 */
export const infoMsg = (paramInfo) => {
  let paramObj = messageTransform(paramInfo);
  return Modal.info({
    title: paramObj.title || '信息',
    content: paramObj.content,
    onOk() {
      if (!paramObj.onOk) return false
      paramObj.onOk()
    },
    okButtonProps: paramObj.okButtonProps
  });
}

/**
 * 全局提示 - 交互信息
 * @param {
 *  title 提示标题
 *  content 内容
 *  onOk 确认
 *  onCancel 取消
 * } param0
 */
export const confirmMsg = ({
  title = '',
  content = '',
  onOk = undefined,
  onCancel = undefined
}) => {
  confirm({
    title: title,
    icon: <ExclamationCircleOutlined />, //图标
    content: content,
    onOk() {
      if (!onOk) return false
      onOk()
    },
    onCancel() {
      if (!onCancel) return false
      onCancel()
    },
  });
}
// 判断IE版本
export const IEVersion = () => {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if(isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if(fIEVersion === 7) {
      return 7;
    } else if(fIEVersion === 8) {
      return 8;
    } else if(fIEVersion === 9) {
      return 9;
    } else if(fIEVersion === 10) {
      return 10;
    } else {
      return 6;//IE版本<=7
    }
  } else if(isEdge) {
    return 'edge';//edge
  } else if(isIE11) {
    return 11; //IE11
  }else{
    return -1;//不是ie浏览器
  }
}

/**
 * 页码改变
 * @param {Function} setFn
 * @param {string} page
 * @param {string} page_size
 */
export const handleChangePageInfo = (page, page_size, setFn) => {
  setFn(prePageInfo => ({
    ...prePageInfo,
    page: page,
    page_size: page_size
  }))
}
//
/**
 * 查询 - resetPage为true 查询时page重置为0 false则不会
 * @param {boolean} resetPage
 * @param {Function} setFn
*/
export const updatePageInfo = (setFn, resetPage = true) => {
  setFn(prePageInfo => ({
    ...prePageInfo,
    page: resetPage ? 1 : prePageInfo.page,
    update_count: ++prePageInfo.update_count
  }))
}

/**
 * 导出excel blob
 * @param  response
 */
export const handleExportExcel = (response, type = "excel") => {
  let mime = ''
  switch (type) {
    case 'excel':
      mime = 'application/vnd.ms-excel;charset=utf-8'
      break
    case 'pdf':
      mime = 'application/pdf'
      break
    default:
  }
  const url = window.URL.createObjectURL(new Blob([response.data], { type: mime }))
  console.log('utils测试 url', url)
  const link = document.createElement('a') // 创建a标签
  link.href = url //设置a标签超链接
  console.log('response', response);
  console.log('response.header', response.header);
  let contentDisposition = response.headers['Content-disposition'] || response.headers['content-disposition'];
  const fileName = decodeURI(splitStr(splitStr(splitStr(contentDisposition, ';')[1], '=')[1], "''")[1] || '') //解码
  link.setAttribute('download', fileName) //添加downLoad属性 规定下载的超链接目标为fileName
  document.body.appendChild(link) //将link标签添加到boyd最后一位
  link.click() //点击link标签
  document.body.removeChild(link) //删除link标签
  window.URL.revokeObjectURL(url) //释放掉blob对象
}
/**
 * 导出excel url
 * @param  res
 */
export const handleExportExcelUrl = (res, type = "excel") => {
  let mime = ''
  switch (type) {
    case 'excel':
      // mime = 'application/vnd.ms-excel;charset=utf-8'
      mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      // mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      break
    case 'pdf':
      mime = 'application/pdf'
      break
    default:
  }
  const url = res.datas
  const link = document.createElement('a') // 创建a标签
  link.href = url //设置a标签超链接
  link.MIME_type = mime
  link.type = mime
  let urlArr = splitStr(splitStr(url, '?')[0], '/')
  const fileName = urlArr[urlArr.length - 1] //解码
  link.setAttribute('download', fileName) //添加downLoad属性 规定下载的超链接目标为fileName
  document.body.appendChild(link) //将link标签添加到boyd最后一位
  link.click() //点击link标签
  document.body.removeChild(link) //删除link标签
  window.URL.revokeObjectURL(url) //释放掉blob对象
}
/**
 * 防抖  非立即执行版
 * @param {Function} fun - 执行的函数
 * @param {Number} delay - 延迟执行的毫秒数
 */
export const debounce = (fun, delay = 300) => {
  let timer = null
  return function (args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fun.call(this, args)
    }, delay);
  }
}
/**
 * 防抖 立即执行版
 * @param {Function} fun  - 执行的函数
 * @param {Number} delay - 延迟执行的毫秒数
 */
export const throttle = (fun, delay = 300) => {
  let canRun = true
  return function () {
    if (!canRun) return
    canRun = false
    setTimeout(() => {
      fun.apply(this, arguments)
      canRun = true
    }, delay)
  }
}


export const transJsonToForm = (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  })
  return formData
}

// 分隔字符串
const splitStr = (oldStr, separator) => {
  return !oldStr ? '' : _.split(oldStr, separator)
}

// 至少填写一个
/**
 * form表单验证，至少输入一个查询条件!
 * @param {Object} form 对象
 * @param {Array} fieldValueArr 需要校验的字段数组
 */
const ruleLeastOne = (form, fieldValueArr) => {
  if (!fieldValueArr.length) return false
  let valueArr = Object.values(form.getFieldsValue(fieldValueArr))
  let isLeastOne = false // 是否至少输入一个查询条件!
  valueArr.forEach(item => {
    let isString = typeof item === 'string' && item.length > 0
    let isNumber = typeof item === 'number'
    if (isString || isNumber) isLeastOne = true
  })
  if (!isLeastOne) {
    Modal.warning({
      title: '警告',
      content: '至少输入一个查询条件!'
    });
    return false
  }
  return isLeastOne
}

// 获取报错信息
const getErrStr = (error) => {
  let isString = typeof error === 'string';
  if (isString) {
    return error
  } else {
    // 修改当前逻辑需与 request.js `axios.interceptors.response.use` `.catch` 方法同步
    let response = error.response
    if (response) {
      let err = response.data
      if (err && response.status === 401) {
        return `${err.message || '未知错误'}, 请重新登录！`
      }
      if (response.status === 504) {
        return '请求超时'
      } else {
        return '未知错误'
      }
    } else {
      if (error && error.toString().indexOf('Error') >= 0) {
        return error.toString()
      } else {
        return '未知错误'
      }
    }
  }
}

// 全局环境区分
export const environmentTypeFun = () => {
  let environmentType = false
  switch(`${process.env.REACT_APP_BASE_TYPE}`){
    case 'LIFE' : return environmentType;
    case 'TK' : return environmentType = true;
    default: return environmentType
  }
}

// 解析权限，生成权限数组对象
const transformAuthority = (datas, parentMenu = { id: 0, url: '' }, result = {}) => {
  let useableDatas = _.isArray(datas) && datas.length > 0
  if (useableDatas) {
    datas.forEach(item => {
      switch (item.menuType) {
        case 'M':
          transformAuthority(item.children, { id: item.id, menuUrl: item.menuUrl }, result)
          break;

        case 'C':
          if ('' + parentMenu.id === '' + item.parentId) {
            result[item.menuUrl] = []
            transformAuthority(item.children, { id: item.id, menuUrl: item.menuUrl }, result)
          }
          break;
        case 'F':
          if ('' + parentMenu.id === '' + item.parentId) {
            result[parentMenu.menuUrl].push(Trim(item.perms))
          }
          break;
        default:
          break;
      }
    })
    return result
  }
}

// 判断菜单权限
const isAuthorityC = (authorityState, C_URL) => {
  if (!authorityState) return false
  return authorityState[C_URL] ? true : false
}
// 判断按钮权限
const isAuthorityF = (authorityState, C_URL, btnAuthorityName) => {
  if (!authorityState) return false
  if (!authorityState[C_URL]) return false
  return authorityState[C_URL].includes(Trim(btnAuthorityName))
}

const str2dom = (strArr) => {
  let str='';
  console.log(strArr)
  strArr.forEach((item) => {
    str+= `<div style="min-height:40px; word-break: break-word;">${item}</div>`
  })
  return <div dangerouslySetInnerHTML={{__html: str}} />;
}

// 去掉前后的空格
const Trim = (str = '') => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 选择开始时间时重制结束时间
 * @param  {[type]} form                            [useForm]
 * @param  {[type]} startName                       [开始时间字段名]
 * @param  {[type]} endName                         [结束时间字段名]
 * @param  {String} [diffType='days']               [开始时间和结束时间差异精确度]
 * @return {[type]}                   [undefined|boolean(false)]
 */
const reChangeStartDate = (form, startName, endName, diffType = 'days') => {
  if (!form) console.error(new Error('reChangeStartDate: form is undefined'));
  if (typeof startName !== 'string') console.error(new Error('reChangeStartDate: 请传入正确的开始时间字段名'));
  if (typeof endName !== 'string') console.error(new Error('reChangeStartDate: 请传入正确的结束时间字段名'));
  if (!['', 'years', 'months', 'days', 'minutes', 'seconds'].includes(diffType)) console.error(new Error('reChangeStartDate: diffType 只接受 years|months|days|minutes|seconds'));
  let startTime = form.getFieldValue()[startName];
  let endTime = form.getFieldValue()[endName];
  if (!startTime) return false
  if (diffType === 'months' && endTime) {
    // 重制结束时间
    let _setVal = {}
    _setVal[endName] = ''
    form.setFieldsValue(_setVal)
  }
  let diffDate = endTime && moment(endTime).diff(moment(startTime), diffType, true)
  if (diffDate >= 0) return false
  let _setVal = {}
  _setVal[endName] = ''
  form.setFieldsValue(_setVal)
}

/**
 * 结束时间弹出时定位到初始时间
 * @param  {[type]} form                            [useForm]
 * @param  {[type]} startName                       [开始时间字段名]
 * @param  {[type]} endName                         [结束时间字段名]
 * @param  {String} [diffType='days']               [开始时间和结束时间差异精确度]
 * @param  {[type]} open                            [弹出状态 开启/关闭]
 * @return {[type]}                   [undefined|boolean(false)]
 */
const openChangeEndDate = (form, startName, endName, diffType = 'days', open) => {
  if (!open) return false
  if (!form) console.error(new Error('reChangeStartDate: form is undefined'));
  if (typeof startName !== 'string') console.error(new Error('reChangeStartDate: 请传入正确的开始时间字段名'));
  if (typeof endName !== 'string') console.error(new Error('reChangeStartDate: 请传入正确的结束时间字段名'));
  if (!['', 'years', 'months', 'days', 'minutes', 'seconds'].includes(diffType)) console.error(new Error('reChangeStartDate: diffType 只接受 years|months|days|minutes|seconds'));
  let startTime = form.getFieldValue()[startName];
  let endTime = form.getFieldValue()[endName];
  if (!startTime) return false
  setTimeout(() => {
    if (!endTime) {
      let _setVal = {}
      _setVal[endName] = startTime
      form.setFieldsValue(_setVal)
    }
    let diffDate = moment(endTime).diff(moment(startTime), diffType, true)
    if (diffDate < 0) {
      let _setVal = {}
      _setVal[endName] = startTime
      form.setFieldsValue(_setVal)
    }
  }, 200)
}

// datepicker时间禁用 根据开始时间限制
/**
 * [disabledDateStart description]
 * @param  {[type]} form                            [useForm]
 * @param  {[type]} startName                       [开始时间字段名]
 * @param  {String} [diffType='days']               [开始时间和结束时间差异精确度]
 * @param  {[type]} current                         [当前时间循环]
 * @return {[type]}                   [Boolean]
 */
const disabledDateStart = (form, startName, diffType = 'days', current) => {
  if (!form) console.error(new Error('reChangeStartDate: form is undefined'));
  if (typeof startName !== 'string') console.error(new Error('disabledDateStart: 请传入正确的开始时间字段'));
  if (!['', 'years', 'months', 'days', 'minutes', 'seconds'].includes(diffType)) console.error(new Error('reChangeStartDate: diffType 只接受 years|months|days|minutes|seconds'));
  let startTime = form.getFieldValue()[startName];
  return (startTime && current) && ( moment(startTime).diff(moment(current), diffType, true) > 0 )
}

// 
/**
 * datepicker时间禁用 根据时间段限制
 * @param  {[type]} form                                  [useForm]
 * @param  {[type]} startName                             [开始字段名]
 * @param  {String} [diffType='days']                     [开始时间和结束时间差异精确度]
 * @param  {String} [diffLimitType='years']               [结束时间限制精确度]
 * @param  {[type]} current                               [当前时间循环]
 * @return {[type]}                         [boolean]
 */
const disabledDateLimitYears = (form, startName, diffType = 'days', diffLimitType = 'years', current) => {
  if (!form) console.error(new Error('reChangeStartDate: form is undefined'));
  if (typeof startName !== 'string') console.error(new Error('disabledDate: 请传入正确的开始时间字段'));
  if (!['', 'years', 'months', 'days', 'minutes', 'seconds'].includes(diffType)) console.error(new Error('reChangeStartDate: diffType 只接受 years|months|days|minutes|seconds'));
  if (!['', 'years', 'months', 'days', 'minutes', 'seconds'].includes(diffLimitType)) console.error(new Error('reChangeStartDate: diffLimitType 只接受 years|months|days|minutes|seconds'));
  let startTime = form.getFieldValue()[startName];
  return (startTime && current) && ( moment(startTime).diff(moment(current), diffType, true) > 0 || current > moment(startTime).endOf(diffLimitType) )
}

// 
/**
 * datepicker时间禁用 根据时间段限制 针对结束时间
 * @param  {[type]} form                                  [useForm]
 * @param  {[type]} startName                             [开始字段名]
 * @param  {String} [diffType='days']                     [开始时间和结束时间差异精确度]
 * @param  {String} [diffLimitType='years']               [结束时间限制精确度]
 * @param  {[type]} current                               [当前时间循环]
 * @return {[type]}                         [boolean]
 */
const disabledDateLimitMonths = (form, startName, endName, current) => {
  if (!form) console.error(new Error('reChangeStartDate: form is undefined'));
  if (typeof startName !== 'string') console.error(new Error('disabledDate: 请传入正确的开始时间字段'));
  if (typeof endName !== 'string') console.error(new Error('reChangeStartDate: 请传入正确的结束时间字段名'));
  let startTime = form.getFieldValue()[startName];
  let endTime = form.getFieldValue()[endName];
  if (!startTime) {
    return false;
  }
  
  let tooLate, tooEarly;
  if (startTime) {
    if (Number(moment(startTime).format("MM")) + 3 > 12) {
      tooLate = current > moment(startTime).endOf("years");
    } else {
      tooLate = current.diff(startTime, "months") >= 3;
    }
  } else if (endTime) {
    if (Number(moment(endTime).format("MM")) - 3 < 0) {
      tooEarly = current < moment(endTime).startOf("years");
    } else {
      tooEarly = endTime.diff(current, "months") >= 3;
    }
  }
  
  return (startTime && current) && ( moment(startTime).diff(moment(current), 'days', true) > 0 || tooEarly || tooLate )
}

// 
/**
 * datepicker时间禁用 根据时间段限制 针对开始时间
 * @param  {[type]} form                                  [useForm]
 * @param  {[type]} startName                             [开始字段名]
 * @param  {String} [diffType='days']                     [开始时间和结束时间差异精确度]
 * @param  {String} [diffLimitType='years']               [结束时间限制精确度]
 * @param  {[type]} current                               [当前时间循环]
 * @return {[type]}                         [boolean]
 */
const disabledDateLimitYearsReversal = (form, endName, current) => {
  if (!form) console.error(new Error('reChangeStartDate: form is undefined'));
  if (typeof endName !== 'string') console.error(new Error('reChangeStartDate: 请传入正确的结束时间字段名'));
  let endTime = form.getFieldValue()[endName];
  
  return (endTime && current) && (Number(moment(endTime).format("YYYY")) !== Number(current.format("YYYY")));
}

//判断是否为递增或递减
const  isContinuityNum=(_num)=>{
  //递增
  var str = _num.replace(/\d/g, function(item, index) {
      return parseInt(item) - index;
  });
//递减
  var str2 = _num.replace(/\d/g, function(item, index) {
      return parseInt(item) + index;
  });
  if (/^(\d)\1+$/.test(str) || /^(\d)\1+$/.test(str2)) {
      return true;
  }
}

//判断是否是空
const isEmpty = (value) => {
  if (value === null || value === "" || value === "undefined" || value === undefined || value === "null") {
      return true;
  }else {
      value = (value + "").replace(/\s/g, '');
      if (value === "") {
          return true;
      }
      return false;
  }
}

//根据身份证获取出生日期 性别
const getBirthByID = (certificateNo) => {
  const iscustomerId = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/;
  //不是身份证号码格式
  if(!iscustomerId.test(certificateNo)) return false;
  let birthday = certificateNo.substring(6, 10) + "-" + certificateNo.substring(10, 12) + "-" + certificateNo.substring(12, 14);
  //性别
  let sex = parseInt(certificateNo.slice(-2, -1)) % 2 === 1 ? '1' : '0';
  return {sex, birthday}

};

//js 减法计算
  //返回值：arg1减arg2的精确结果
  const Subtr = ((arg1, arg2) => {
    arg1 = parseFloat(arg1);
    arg2 = parseFloat(arg2);
    var r1, r2, m, n;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((Mul(arg1, m) - Mul(arg2, m)) / m).toFixed(n);
  })

  //乘法函数，用来得到精确的乘法结果
  //说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
  //调用：Mul(arg1,arg2)
  //返回值：arg1乘以arg2的精确结果
  const Mul = ((arg1, arg2) => {
    arg1 = parseFloat(arg1);
    arg2 = parseFloat(arg2);
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try { m += s1.split(".")[1].length } catch (e) { }
    try { m += s2.split(".")[1].length } catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
  })

//加法
const accAdd = (num1, num2) => {
  let r1, r2, m;
  try {
      r1 = `${num1}`.split('.')[1].length;
  } catch (e) {
      r1 = 0;
  }
  try {
      r2 = `${num2}`.split('.')[1].length;
  } catch (e) {
      r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return Math.round(num1 * m + num2 * m) / m;
};


export default {
  str2dom,                    // 文本空格
  handleChangePageInfo,       // 分页改变方法
  updatePageInfo,             // 查询调用方法
  handleExportExcel,          // 导出excel方法
  handleExportExcelUrl,       // 导出excel方法(url)
  debounce,                   // 防抖 - 非立即执行版
  throttle,                   // 防抖 - 立即执行版
  successMsg,                 // 全局提示 - 成功
  errorMsg,                   // 全局提示 - 失败
  warnMsg,                    // 全局提示 - 警告
  infoMsg,                    // 全局提示 - 信息
  confirmMsg,                 // 全局交互
  transJsonToForm,            // json数据转换成formData
  ruleLeastOne,               // 搜索表单至少输入或选择一个
  splitStr,                   // split 分隔方法
  getErrStr,                  // 获取报错信息的字符串
  environmentTypeFun,          // 全局变量区分
  transformAuthority,            // 解析权限数组，生成新的数组对象
  isAuthorityF,                     // 判断是否有按钮权限
  isAuthorityC,                      // 判断是否有菜单权限
  IEVersion,                     // 获取IE版本
  reChangeStartDate,
  openChangeEndDate,
  disabledDateStart,
  disabledDateLimitYears,
  disabledDateLimitMonths,
  disabledDateLimitYearsReversal,
  isContinuityNum,//判断是否为递增或递减
  isEmpty,//判断是否为空
  getBirthByID,  //根据身份证号码获取性别和出生年月
  Subtr, //减法计算
  Mul, //乘法计算
  accAdd, //加法计算
}
