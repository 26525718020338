const numberInteger = /^-?\d+$/
const numberDecimals = /^-?\d+(\.\d)?$/
const numberTwoDecimals = /^\d+\.?\d{0,2}$/
const numberFourDecimals = /^\d+\.?\d{0,4}$/
const positiveInteger = /^[1-9]+[0-9]*]*$/
// const userPasswordRegular = /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])).{6,}|(?:(?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[^A-Za-z0-9])|(?=.*[0-9])(?=.*[^A-Za-z0-9])|).{8,20}$/
const userPasswordRegular = /^[a-zA-Z\d]\w{5,14}$/
const userRegular = /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])).{6,}|(?:(?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[^A-Za-z0-9])|(?=.*[0-9])(?=.*[^A-Za-z0-9])|)$/
const userNameRegular = /^[\u4e00-\u9fa5]+$/;
const emailRegular = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
const telephone = /^1[3456789]\d{9}$/
const userSpecific = /^.{8,20}$/
const numberThousandthSeparator = /(\d)(?=(\d{3})+(\.|$))/g
const floatInteger = /^[-+]?\d+$/
const stringIp = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/
const numberSwitch = /^[0-1]+$/
export {
    numberInteger,
    numberDecimals,
    numberTwoDecimals,
    numberFourDecimals,
    positiveInteger,
    userRegular,
    userNameRegular,
    emailRegular,
    telephone,
    userPasswordRegular,
    userSpecific,
    numberThousandthSeparator,
    floatInteger,
    stringIp,
    numberSwitch
}
