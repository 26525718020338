import Model from '../index'
import { Utils } from '@/utils'

class Dictionary extends Model {
  constructor() {
    super()
    this.requestServerPrefix = '/system'
  }
  // 获取所有字典类型
  selectDictHeadList(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {})
    options.url = '/web/dictHead/selectDictHeadList'
    return this.get(options)
  }
  // 查询字典列表
  selectDictDetail(options = {}) {
    // options.data = Utils.transJsonToForm(options.data || {})
    options.url = '/web/dictHead/selectDictDetail'
    return this.post(options)
  }
  // -添加字典类型
  addDictHead(options = {}) {
    // options.data = Utils.transJsonToForm(options.data || {})
    options.url = '/web/dictHead/addDictHead'
    return this.post(options)
  }
  // 根据id查询字典类型
  getDictHeadById(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {})
    options.url = '/web/dictHead/getDictHeadById'
    return this.get(options)
  }
  // 更新字典类型
  updateDictHead(options = {}) {
    // options.data = Utils.transJsonToForm(options.data || {})
    options.url = '/web/dictHead/updateDictHead'
    return this.post(options)
  }
  // 添加字典列表
  addDictDetail(options = {}) {
    // options.data = Utils.transJsonToForm(options.data || {})
    options.url = '/web/dictHead/addDictDetail'
    return this.post(options)
  }
  // 更新字典列表
  updateDictDetail(options = {}) {
    // options.data = Utils.transJsonToForm(options.data || {})
    options.url = '/web/dictHead/updateDictDetail'
    return this.post(options)
  }


}

const dictionaryModel = new Dictionary()
export default dictionaryModel
