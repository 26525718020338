// 按钮
import React from "react";
import { Modal, Button } from 'antd';

import "./index.less";

// props 参数
    //  visible 是否可见 boolean
    //  width 宽度 string '900px'
    //  title 标题 string
    //  content 内容 element
    //  noBtn 显示按钮 boolean
    //  btns 按钮 btns: [ { text: '打印小票', handleClick: () => { console.log('关闭') } },{ text: '查看详情', handleClick: () => { console.log('查看详情') } },],

const AnInfoMsg = (props) => {
  const handleOk = () => {
    let isFun = typeof props.onOk === 'function'
    if (isFun) {
      props.onOk()
    }
  }

  const handleCancel = () => {
    let isFun = typeof props.onCancel === 'function'
    if (isFun) {
      props.onCancel()
    }
  }
  return (
    <Modal
      wrapClassName={props.wrapClassName || "vertical-center-modal info-msg__container"}
      title={props.title || '提示'}
      visible={props.visible}
      width={props.width || '500px'}
      closable={props.closable === undefined ? true : props.closable ? true : false}
      footer={!props.noBtn && (
        props.btns ?
        props.btns.map((item, index) => {
          return <Button key={index} size="large" type="primary" onClick={item.handleClick}>{item.text}</Button>
        }) :
        <Button size="large" type="primary" onClick={handleOk.bind(this)}>{props.okText || '确定'}</Button>
      )}
      onCancel={handleCancel.bind(this)}
    >

      { props.children ?
        props.children :
        <div className="modal-msg__content">{props.content}</div>
      }
    </Modal>
  )
}

export default AnInfoMsg;
