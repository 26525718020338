import React, { Suspense } from "react"
import { Switch, Route, Redirect } from "react-router"

import { Loading } from '@/app/Layout/index'

import { Storage } from '@/utils'

function renderRoutes(routes, authed, authPath, extraProps = {}, switchProps = {}) {
  return routes ? (
    <Suspense fallback={<Loading />}>
      <Switch {...switchProps}>
        {routes.map((route, i) => (
          <Route
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props => {
              let Authorization = Storage.getStorage('Authorization')
              let userName = Storage.getStorage('userName')
              let noLogin = !Authorization || !userName
              let pathname = window.location.pathname
              if (pathname !== '/login' && pathname !== '/company-system-updatePassword'  && noLogin) {
                window.location.href = '/login'
              }
              if( !route.restricted || authed || route.path === authPath) {
                return route.render ? (
                  route.render({ ...props, ...extraProps, route: route })
                ) : (
                  <route.component {...props} {...extraProps} route={route} />
                )
              }
              const redirPath = authPath ? authPath : '/login'
              return <Redirect to={{pathname: redirPath, state: {from: props.location}}}/>
              }
            }
          />
        ))}
      </Switch>
    </Suspense>
  ) : null;
}

export default renderRoutes;
