import React, { memo, useState, useEffect, useRef } from "react";
import renderRoutes from "@/routes/renderRoutes";
// antd
import { ConfigProvider, Layout, Modal } from "antd";
import { withRouter, Link } from "react-router-dom";
// components
import { UserMenu } from "@/app/Layout";

// redux
import store from "@/store/index";
import { Provider } from "react-redux";
import { useHistory } from "react-router-dom";
import loginModel from "@/model/Login/loginModel";
// utils
import { Utils, Storage, LoadingRef } from "@/utils";
//less
import "./UserLayout.less";
// common img
import imgHome from "@/assets/home.png";
import imgLogout from "@/assets/logout.png";
import imgPhone from "@/assets/phone.png";
import imgHelp from "@/assets/help.png";
import imgShop from "@/assets/shopico.png";
import UserHomeModel from "@/model/UserHome/UserHomeModel";
import LoadingAlert from '@/components/LoadingAlert';

// life
const headerRs = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerRS.png";
//tk
const headerTk = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerTk.png";

const { Sider } = Layout;

const UserLayout = ({ route }) => {
  const loadingRef = useRef();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  const LogOut = () => {
    loginModel
      .userLogout()
      .then(res => {
        window.localStorage.clear();
        window.localStorage.href = "/";
        window.location.reload()
        // history.replace("/");
      })
      .catch(err => {
        history.replace("/");
      });
  };

  const handleHelp = () => {
    /* tk:true  gs:false
      loginType  1后台端，2保险端，3投保企业端，4商户/企健付端, 6持卡人端
    */
    let urlType = "";
    if(Utils.environmentTypeFun()){//泰康端
      switch (Storage.getStorage("loginType")) {
        case '2':
          urlType = "TK-insurance";
          break;
        case '3':
          urlType = "TK-company";
          break;
        case '6':
          urlType = "TK-person";
          break;
        default:
          break;
      }
    }else{//国寿端
      switch (Storage.getStorage("loginType")) {
        case '2':
          urlType = "GS-insurance";
          break;
        case '3':
          urlType = "GS-company";
          break;
        case '6':
          urlType = "GS-person";
          break;
        default:
          break;
      }
    }
    const HelpUrl = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/"+ urlType +".pdf";
    window.open(HelpUrl);
  };

  useEffect(() => {
    UserHomeModel.getCardCustomerInfo()
      .then(({ datas }) => {
        let options = datas;
        // options.mobilePhone = 0;
        if (
          !options.mobilePhone ||
          options.mobilePhone === 0 ||
          options.mobilePhone === "0" ||
          options.mobilePhone === ""
        ) {
          Modal.warning({
            title: "提示",
            content:
              "尊敬的企健网客户，您好！您的卡号尚未绑定手机号，为了您的卡号安全，请您绑定手机号",
            okText: "前往绑定",
            onOk: () => {
              history.replace("/binding-phone");
            }
          });
        }
        // options.mobilePhone = 0
        setUserInfo(options);
        // status 0正常, 1无效, 3未激活, 4普通冻结，5特殊冻结
        for (let k in options) {
          if (k === "status") {
            switch (options[k]) {
              case 0:
                options[k] = "正常";
                break;
              case 1:
                options[k] = "无效";
                break;
              case 3:
                options[k] = "未激活";
                break;
              case 4:
                options[k] = "普通冻结";
                break;
              case 5:
                options[k] = "特殊冻结";
                break;
              default:
                options[k] = "其他状态";
                break;
            }
          } else if (k === "externalFlag") {
            if (options[k] === 0) {
              options[k] = "第三方";
            } else {
              options[k] = "企健网";
            }
          }
        }
        Storage.setStorage("userInfo", options);
      })
      .catch(err => {
        console.log(err);
      });
    loginModel
      .userLoginExpire()
      .then(({ message }) => {
        if (!message) {
          Modal.warning({
            title: "登录超时",
            content: "请重新登录",
            okText: "确认",
            onOk: () => {
              history.replace("/");
            }
          });
        }
      })
      .catch(err => {
        history.replace("/");
      });
  }, [history]);

  useEffect(() => {
    LoadingRef.setRef(loadingRef)
  }, [loadingRef])

  return (
    <ConfigProvider componentSize="middle">
      <div className="header-img">
        <img
          alt="头部标题图片"
          src={Utils.environmentTypeFun() ? headerTk : headerRs}
        />
      </div>
      <div className="wrap__header-nav-user">
        <div className="nav__heder-area">
          <Link to="/user/home">
            <div className="nav__home">
              <img className="nav__home-img" src={imgHome} alt="首页的图标" />
              首页
            </div>
          </Link>
        </div>
        <div className="nav__detail">
          <div className="nav__detail--left">
            <a target="_black" href={userInfo.mallUrl}>
              <img src={imgShop} alt="首页的图标" />
              进入商城
            </a>
          </div>
          <div className="nav__detail--right">
            <span onClick={handleHelp}>
              <img className="nav__detail-img" src={imgHelp} alt="帮助中心" />
              帮助中心
            </span>


            <span>
              <img
                className="nav__detail-img"
                src={imgPhone}
                alt="联系我们的图标"
              />
              联系我们： {Utils.environmentTypeFun() ? "400-66-95522" : "400-812-6880"}
            </span>
            <span onClick={LogOut}>
              <img
                className="nav__detail-img"
                src={imgLogout}
                alt="安全退出的图标"
              />
              安全退出
            </span>
          </div>
        </div>
      </div>
      <Provider store={store}>
      {/* minHeight: "100vh" */}
        <Layout
          style={{ }}
          className="basic-layout__container-user"
        >
          <Layout className="site-layout">
            <div id="content">
              <div className="site-layout__box-user">
                {renderRoutes(route.routes)}
              </div>
            </div>
          </Layout>
          <Sider>
            <UserMenu />
          </Sider>
        </Layout>

        <LoadingAlert ref={loadingRef} />
      </Provider>
    </ConfigProvider>
  );
};

export default memo(withRouter(UserLayout));
