import Model from "../index";
// 引入公共方法
import { Utils } from "@/utils";

class UserHome extends Model {
  constructor() {
    super();
    this.requestServerPrefix = "/card";
  }
  // 查询卡号信息(上次登录时间，余额，投保单位)
  getCardCustomerInfo(options = {}) {
    options.url = "/web/cardCustomer/getCardCustomerInfo";
    return this.get(options);
  }
  // 卡冻结
  ptFreeze(options = {}) {
    options.url = "/web/cardCustomer/ptFreeze";
    return this.post(options);
  }
  // 卡激活
  cardActivation(options = {}) {
    // options.data = Utils.transJsonToForm(options.data);
    options.url = "/web/cardCustomer/cardActivation";
    return this.post(options);
  }
  // 修改支付密码
  updatePayPassword(options = {}) {
    options.url = "/web/cardCustomer/updatePayPassword";
    return this.post(options);
  }
  // 修改登录密码
  updateLoginPassword(options = {}) {
    options.url = "/web/cardCustomer/updateLoginPassword";
    return this.post(options);
  }
  // 绑定手机号
  bindMobilePhone(options = {}) {
    options.url = "/web/cardCustomer/bindMobilePhone";
    return this.post(options);
  }
  // 修改手机号-步骤1
  updateMobilePhone1(options = {}) {
    options.url = "/web/cardCustomer/updateMobilePhone1";
    return this.post(options);
  }
  // 修改手机号-步骤2
  updateMobilePhone2(options = {}) {
    options.url = "/web/cardCustomer/updateMobilePhone2";
    return this.post(options);
  }
  // 忘记登录密码
  forgetLoginPassword(options = {}) {
    options.url = "/web/cardCustomer/forgetLoginPassword";
    return this.post(options);
  }
  // 校验手机号卡号相符
  sendSmsCheckInfo(options = {}) {
    options.data = Utils.transJsonToForm(options.data)
    options.url = '/web/cardCustomer/checkInfo'
    return this.post(options)
  }
}

const UserHomeModel = new UserHome();
export default UserHomeModel;
