
// 更新侧边栏展开状态
export function updateCollapsed (data) {
  console.log(data);
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_COLLAPSED', payload: data })
  }
}

export function updateDictionaryType (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_DICTIONARY_TYPE', payload: data })
  }
}

// 更新权限对象
export function updateAuthority (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_AUTHORITY', payload: data })
  }
}
