import React, { memo, useEffect, useState } from "react";
// antd
import { withRouter, Link } from "react-router-dom";
import { Storage } from "@/utils";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import UserHomeModel from "@/model/UserHome/UserHomeModel";
//less
import "./UserMenu.less";
// common img
import user from "@/assets/user.png";
// import message from '@/assets/message.png'
import icon1 from "@/assets/icon1.png";
import icon2 from "@/assets/icon2.png";
import icon3 from "@/assets/icon3.png";
import icon4 from "@/assets/icon4.png";
import arrRightIcon from "@/assets/arrRightIcon.png";

const UserMenu = ({ location }) => {
  const [userInfo, setUserInfo] = useState({});
  const [status, setStatus] = useState('')
  const history = useHistory();

  const isLink = url => {
    if (Storage.getStorage("userInfo").status === "普通冻结"||Storage.getStorage("userInfo").status === "特殊冻结")
      return Modal.warning({
        title: "卡号冻结",
        content: "卡片已冻结，请联系管理员解冻！",
        okText: "确认"
      });
    history.replace(url);
  };

  useEffect(() => {
    UserHomeModel.getCardCustomerInfo()
      .then(({ datas }) => {
        let options = datas;
        options.mobilePhone = 0;
        setUserInfo(options);
        // status 0正常, 1无效, 3未激活, 4普通冻结，5特殊冻结
        for (let k in options) {
          if (k === "status") {
            switch (options[k]) {
              case 0:
                options[k] = "正常";
                break;
              case 1:
                options[k] = "无效";
                break;
              case 3:
                options[k] = "未激活";
                break;
              case 4:
                options[k] = "普通冻结";
                break;
              case 5:
                options[k] = "特殊冻结";
                break;
              default:
                options[k] = "其他状态";
                break;
            }
          } else if (k === "externalFlag") {
            if (options[k] === 0) {
              options[k] = "第三方";
            } else {
              options[k] = "企健网";
            }
          }
        }
        Storage.setStorage("userInfo", options);
        setStatus(options.status)
      })
      .catch(err => {
        console.log(err);
      });
  }, [history]);

  return (
    <div className="wrap__userMenu">
      <div className="userMenu-header">
        <img className="user-icon" src={user} alt="用户头像" />
        <div className="user-info">
          <div>尊敬的 {userInfo.customerName} 您好！</div>
          <div>
            上次登录时间：<div>{userInfo.lastLoginTime}</div>
          </div>
          {/* <Link to='/user/message'>
                        <div className='use-msg'>
                            <img className='user-msg-icon' src={message} alt='信息' /> 站内消息
                        </div>
                    </Link> */}
        </div>
      </div>
      <div className="userMenu-content">
        <div className="userMenu-item">
          <div className="userMenu-item-box">
            <div className="userMenu-item-area">
              <img className="userMenu-item-img" src={icon1} alt="icon" />
              <div className="icon-title">卡片信息</div>
            </div>
            <div className="userMenu-item-list">
              <div
                className="list-item"
                onClick={isLink.bind(this, "/user/card-info/card-status")}
              >
                <img src={arrRightIcon} alt="箭头" /> 状态
              </div>
              <div
                className="list-item"
                onClick={isLink.bind(this, "/user/card-info/card-freeze")}
              >
                <img src={arrRightIcon} alt="箭头" /> 冻结
              </div>
              {status === "未激活" && (
                  <div
                className="list-item"
                onClick={isLink.bind(this, "/user/card-info/card-activation")}
              >
                <img src={arrRightIcon} alt="箭头" /> 激活
              </div>

              )}
            </div>
          </div>
        </div>
        <div className="userMenu-item">
          <div className="userMenu-item-box">
            <Link to="/user/transaction-details">
              <div className="userMenu-item-area">
                <img className="userMenu-item-img" src={icon2} alt="icon" />
                <div className="icon-title">消费明细</div>
              </div>
            </Link>
          </div>
        </div>
        <div className="userMenu-item">
          <div className="userMenu-item-box">
            <div className="userMenu-item-area">
              <img className="userMenu-item-img" src={icon3} alt="icon" />
              <div className="icon-title">修改密码</div>
            </div>
            <div className="userMenu-item-list">
              <div
                className="list-item"
                onClick={isLink.bind(
                  this,
                  "/user/edit-password/reset-transaction-password"
                )}
              >
                <img src={arrRightIcon} alt="箭头" /> 修改支付密码
              </div>

              <div
                className="list-item"
                onClick={isLink.bind(
                  this,
                  "/user/edit-password/reset-login-password"
                )}
              >
                <img src={arrRightIcon} alt="箭头" /> 修改登录密码
              </div>
              <div
                className="list-item"
                onClick={isLink.bind(this, "/user/edit-password/reset-phone")}
              >
                <img src={arrRightIcon} alt="箭头" /> 修改手机号
              </div>
            </div>
          </div>
        </div>
        <div className="userMenu-item">
          <div className="userMenu-item-box">
            <div
              className="userMenu-item-area"
              onClick={isLink.bind(this, "/user/networl-details")}
            >
              <img className="userMenu-item-img" src={icon4} alt="icon" />
              <div className="icon-title">服务网络</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(withRouter(UserMenu));
