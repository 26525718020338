import dictionaryModel from '@/model/SystemSetting/dictionaryModel';
import commonModel from '@/model/Global/common';

const LIST = {
  // 账号/工号状态
  STATUS_LIST: [
    { value: 0, title: "正常" },
    { value: 1, title: "禁用" }
  ],
  // 登录角色
  USER_ROLE_LIST: [
    { value: 0, title: "管理员" },
    { value: 1, title: "后台" },
    { value: 2, title: "保险公司" },
    { value: 3, title: "企业" },
    { value: 4, title: "商户" }
  ],
  // 菜单角色
  MENU_ROLE_LIST: [],
  // 企健付版本号/读卡器类型
  CARDREADER_TYPE_LIST: [
    { value: 1, title: "POS" },
    { value: 2, title: "刷卡" },
    { value: 3, title: "手机" },
    { value: 4, title: "区分操作手册" }
  ],
  // 是否
  WHETHER_LIST: [
    { value: 0, title: "是" },
    { value: 1, title: "否" }
  ],
  // 是否
  WHETHER_LIST_REVERSE: [
    { value: 0, title: "否" },
    { value: 1, title: "是" }
  ],
  //是否启用
  ENABLE_LIST: [
    { value: 0, title: "未启用" },
    { value: 1, title: "启用" }
  ],
  //第三方商城标识
  MALLID_LIST: [
    { value: 0, title: "九源洪福堂保外都显示" },
    { value: 1, title: "九源洪福堂保外都不显示" },
    { value: 3, title: "不显示保外，九源洪福堂都显示" },
    { value: 4, title: "只显示保外，九源洪福堂不显示" }
  ],
  //证件类型
  IDType_LIST: [
    { value: 0, title: "身份证" },
    { value: 1, title: "护照" },
    { value: 2, title: "驾照" }
  ],
  // 补卡类型
  CARD_TYPE: [
    { value: 0, title: "企健网发卡" },
    { value: 1, title: "非企健网发卡" }
  ],
  // 登记状态
  CARD_STATUS: [
    { value: 0, title: "未登记" },
    { value: 1, title: "登记成功" },
    { value: 2, title: "登记失败" }
  ],
  // 团单状态  0：正常；1：冻结；2：废除
  GROUP_ORDER_STATUS: [
    { value: 0, title: "正常" },
    { value: 1, title: "冻结" },
    { value: 2, title: "废除" }
  ],
  // 税号状态 0 未填充，1已填充
  TAX_NO_STATUS: [
    { value: 0, title: "未填充" },
    { value: 1, title: "已填充" }
  ],
  // 团单扣款方式 0本地 1泰康端
  ORDER_PAY_TYPE: [
    { value: 0, title: "本地" },
    { value: 1, title: "泰康端" }
  ],
  // 订单状态 0 正常 1 取消 0 退单
  ORDER_STATUS: [
    { value: 0, title: "正常" },
    { value: 1, title: "取消" },
    { value: 2, title: "退单" }
  ],
  // 付款状态 0 已付款 1 未付款
  PAYMENT_STATUS: [
    { value: 0, title: "已付款" },
    { value: 1, title: "未付款" }
  ],
  // 处理结果 0 已处理 1 未处理
  PROCESS_RESULT: [
    { value: 0, title: "已处理" },
    { value: 1, title: "未处理" }
  ],
  // 商品状态
  COMMODITY_STATUS: [
    { value: 0, title: "未分类" },
    { value: 1, title: "分类中" },
    { value: 2, title: "分类完成" },
    { value: 3, title: "审核完成" }
  ],
  // 预约单状态
  APPOINTMENT_STATUS: [
    { value: 0, title: "已预约" },
    { value: 1, title: "已取消" },
    { value: 2, title: "已体检" },
    { value: 3, title: "预约失败" },
    { value: 4, title: "预约中" },
    { value: 5, title: "已出报告" },
    { value: 6, title: "改期中" },
    { value: 7, title: "未到检" }
  ],
  // 理赔日志 操作类型   0新增 1修改 2取消 3导入
  LOG_CLAIM_TYPE: [
    { value: 0, title: "新增" },
    // { value: 1, title: "修改" },
    { value: 2, title: "取消" },
    // { value: 3, title: "导入" }
  ],
  // 卡片冻结 操作类型   0 卡丢失 1 卡受损 2 卡消磁 3 暂不使用
  USER_CARD_STATUS: [
    { value: 0, title: "卡丢失" },
    { value: 1, title: "卡受损" },
    { value: 2, title: "卡消磁" },
    { value: 3, title: "暂不使用" }
  ]
};
class ClassGlobal {
  constructor() {
    this.constantKey = {
      accountStatus: "STATUS_LIST",
      userRole: "USER_ROLE_LIST",
      carderaderType: "CARDREADER_TYPE_LIST",
      whether: "WHETHER_LIST",
      enable: "ENABLE_LIST",
      mallId: "MALLID_LIST",
      IDType: "IDType_LIST",
      cardType: "CARD_TYPE",
      cardStatus: "CARD_STATUS",
      groupOrderStatus: "GROUP_ORDER_STATUS",
      taxNoStatus: "TAX_NO_STATUS",
      orderPayType: "ORDER_PAY_TYPE",
      commodityStatus: "COMMODITY_STATUS",
      qjSaleFlag: "WHETHER_LIST_REVERSE",
      isSmartpay: "WHETHER_LIST_REVERSE",
      processResult: "PROCESS_RESULT",
      appointmentStatus:"APPOINTMENT_STATUS",
      logClaimType:"LOG_CLAIM_TYPE",
      userCardStatus:"USER_CARD_STATUS"
    };
    this.dictionaryKey = {
      'dictHead': 'selectDictHeadList', // 所有字典类型
      'menuRole': 'selectRoleList', // 菜单功能角色
      'dictionaryCommon': "selectDictionaryCommon",//字典项通用
      'healthyInfoType': "selectInfoType", // 咨询类型
      'provinceAreaList': 'selectProvinceAreaList', // 省地区列表
      'areaList': 'selectAreaList', // 明细地区列表
      'operationModule': "selectOperation",//操作模块列表
      'advertisement': "selectAdvertisement",//广告渠道
      'operationType': 'selectOperationType',//操作类型
      'cardGStatus': 'selectCardStatus',//卡状态
      'cardGType': 'selectCardType',//卡类型
      'announcement': 'selectAnnouncement',//公告渠道
      'companyName': 'selectCompanyName', // 承保单位模糊查询
      'storeType':'selectStoreType',//门店类型
      'docking':'selecctDocking',//对接方式
      'qjCommodityType': 'selectQjCommodityType', // 企健网商品分类
      'organizationType': "selectOrganizationType", //机构类型
      'organizationTypeNew': "selectOrganizationTypeNew", //机构类型
      'orderStatus': "selectOrderStatus", // 订单状态
      'brandByType': "selectBrandByType", //根据类型获取品牌
      'insureCompanyName': 'selectInsureCompanyName', // 投保单位模糊查询
      'policyType': "selectDictionary",//字典查询下拉
      'userRoleDic': 'selectUserRoleDic', // 登录角色（字典类型）
      'commodityLibrary':"selectCommodityLibrary", //结算主体管理 商品库
    }

    this.dictionary = {}
    this.dictHeadList = [] // 所有字典类型
    this.menuRoleList = [] // 菜单功能角色
    this.dictionaryCommonList = []; // 字典项通用
    this.infoTypeList = [] //健康资讯类型
    this.provinceAreaList = [] // 省地区列表
    this.areaList = [] // 明细地区列表
    this.operationList = []//操作模块
    this.advertisementList = [] //广告渠道
    this.operationTypeList = [] //操作类型
    this.cardStatusList = [] //卡状态
    this.cardTypeList = [] //卡类型
    this.announcementList = [] //公告渠道
    this.companyNameList = [] // 承保单位模糊查询
    this.storeTypeList  = [] //门店类型
    this.storeTypeListNew  = [] //门店类型
    this.dockingList = [] //对接方式
    this.qjCommodityTypeList = [] //企健网商品分类-药品白名单列表
    this.dictionaryList = []; //字典查询下拉
    this.userRoleDic = [] // 登录角色-字典方式
    this.commodityLibrary = [] // 结算主体管理 - 商品库
  }

  /**
   *
   * @param {string} dictionaryKey 目录名称
   * @param {string} defaultVal 默认字段
   * @param {object} options 参数
   */
  getOptionsList(dictionaryKey, defaultVal = "请选择", options = null) {
    let _constantKey = this.constantKey[dictionaryKey];
    let _dictionaryKey = this.dictionaryKey[dictionaryKey];
    if (!_constantKey && !_dictionaryKey) return [];
    let type = _constantKey ? "CONSTANT" : "FUNCTION";
    switch (type) {
      case "CONSTANT":
        if (!_constantKey) return [];
        return this.setSelectDefaultVal(LIST[_constantKey], defaultVal);

      case "FUNCTION":
        if (!_dictionaryKey) return [];
        return this[_dictionaryKey](defaultVal, options);

      default:
        return [];
    }
  }

  // 添加默认option下拉选项
  setSelectDefaultVal(list, defaultVal = "请选择") {
    if (!list) return [{ value: "", title: defaultVal }];
    let _list = list.slice(0);
    _list.unshift({ value: "", title: defaultVal });
    return _list;
  }

  // 获取所有字典类型
  selectDictHeadList(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      dictionaryModel
        .selectDictHeadList({
          params: {
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.headCode,
                title: res.headName
              };
            });
            this.dictHeadList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.dictHeadList);
          } else {
            reject({ message: "获取所有字典类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取所有字典类型失败", err: err });
        });
    });
  }

  // 获取菜单功能角色
  selectRoleList(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getListRole({
          data: {
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.roleName
              };
            });
            this.menuRoleList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.menuRoleList);
          } else {
            reject({ message: "获取菜单功能角色失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取菜单功能角色失败", err: err });
        });
    });
  }

  //获取字典数据（通用）
  selectDictionaryCommon(defaultVal = "请选择", headCode) {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: headCode,
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.dictionaryCommonList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.dictionaryCommonList);
          } else {
            reject({ message: "获取字典项失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取字典项失败", err: err });
        });
    });
  }

  //获取资讯类型
  selectInfoType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getHealthyInfoType({
          data: {
            pageNum: 1,
            pageSize: 100,
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.list.length) {
            let datas = res.datas.list.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.title
              };
            });
            this.infoTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.infoTypeList);
          } else {
            reject({ message: "获取资讯类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取资讯类型失败", err: err });
        });
    });
  }
  // 省地区列表
  selectProvinceAreaList(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getProvinceList({
          data: {
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.areaName
              };
            });
            this.provinceAreaList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.provinceAreaList);
          } else {
            reject({ message: "获取地区列表失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取地区列表失败", err: err });
        });
    });
  }
  // 地区列表
  selectAreaList(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .getAreaList({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.areaName
              };
            });
            this.areaList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.areaList);
          } else {
            reject({ message: "获取地区列表失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取地区列表失败", err: err });
        });
    });
  }
  // 品牌类型
  selectBrandByType(defaultVal = "请选择", options) {
    console.log(defaultVal, options);
    return new Promise((resolve, reject) => {
      commonModel
        .getBrandByType({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas.list) {
            let datas = res.datas.list.slice(0);
            let list = datas.map(res => {
              return {
                title: res.bigBrand
              };
            });
            this.brandByType = this.setSelectDefaultVal(list);
            function unique(arr) {
              var newArr = [];
              for (var i = 0; i < arr.length; i++) {
                if (newArr.indexOf(arr[i]) === -1) {
                  newArr.push(arr[i]);
                }
              }
              return newArr;
            }
            let arr = unique(this.brandByType);
            arr.forEach(item => {
              item = { title: item };
            });
            resolve(arr);
          } else {
            reject({ message: "获取品牌失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取品牌失败", err: err });
        });
    });
  }
  //操作模块
  selectOperation(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "sys_optlog_module",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.sortCode,
                title: res.dictName
              };
            });
            this.operationList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.operationList);
          } else {
            reject({ message: "获取操作模板失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取操作模板失败", err: err });
        });
    });
  }
  //发布渠道
  selectAdvertisement(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_adv_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName,
                disabled: res.status === 1 ? true : false
              };
            });
            this.advertisementList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.advertisementList);
          } else {
            reject({ message: "获取发布渠道失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取发布渠道失败", err: err });
        });
    });
  }
  //操作类型
  selectOperationType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "card_opt_flag",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.operationList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.operationList);
          } else {
            reject({ message: "获取操作类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取操作类型失败", err: err });
        });
    });
  }
  //卡号生成 -- 卡状态
  selectCardStatus(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "c_card_status",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          console.log(res);
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.operationList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.operationList);
          } else {
            reject({ message: "获取卡状态失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取卡状态失败", err: err });
        });
    });
  }

  //卡号生成 -- 卡类型
  selectCardType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "c_card_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.cardTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.cardTypeList);
          } else {
            reject({ message: "获取卡类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取卡类型失败", err: err });
        });
    });
  }
  //公告发布渠道
  selectAnnouncement(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_notice_flag",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.announcementList = list;
            resolve(this.announcementList);
          } else {
            reject({ message: "获取发布渠道失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取发布渠道失败", err: err });
        });
    });
  }
  //门店管理-门店类型
  selectStoreType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_company_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取门店类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取门店类型失败", err: err });
        });
    });
  }
  // 团单管理 - 承保单位模糊查询
  selectCompanyName(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel.listInsuranceCompanyInfo({
        data: {
          ...(options.data ? options.data : {}),
          noLoadingRef: 'yes'
        }
      }).then(res => {
        if (res.datas && res.datas.length) {
          let datas = res.datas.slice(0)
          let list = datas.map(res => {
            return {
              id: res.id,
              value: res.companyName,
              title: res.companyName
            }
          })
          this.cardTypeList = this.setSelectDefaultVal(list, defaultVal)
          resolve(this.cardTypeList)
        } else {
          reject({ message: "获取承保单位模糊查询失败", err: res, list: this.setSelectDefaultVal([], defaultVal) })
        }
      }).catch(err => {
        reject({ message: "获取承保单位模糊查询失败", err: err, list: this.setSelectDefaultVal([], defaultVal) })
      })
    })
  }
  //门店管理 - 对接方式
  selecctDocking(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_company_connect_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.dockingList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.dockingList);
          } else {
            reject({ message: "获取对接方式失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取对接方式失败", err: err });
        });
    });
  }

  //企健网商品分类-药品白名单列表
  selectQjCommodityType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "store_qj_commodity_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.qjCommodityTypeList = list;
            resolve(this.qjCommodityTypeList);
          } else {
            reject({ message: "获取企健网商品分类失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取企健网商品分类失败", err: err });
        });
    });
  }

  // 订单管理 - 机构类型选择
  selectOrganizationType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_company_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取门店类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取门店类型失败", err: err });
        });
    });
  }
  // 订单管理 - 机构类型选择
  selectOrganizationTypeNew(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_strategy_company_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeListNew = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeListNew);
          } else {
            reject({ message: "获取门店类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取门店类型失败", err: err });
        });
    });
  }
  // 团单管理 - 投保单位模糊搜索
  selectInsureCompanyName(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel.listInsureCompanyInfo({
        data: {
          ...(options.data ? options.data : {}),
          noLoadingRef: 'yes'
        }
      }).then(res => {
        if (res.datas && res.datas.length) {
          let datas = res.datas.slice(0)
          let list = datas.map(res => {
            return {
              id: res.id,
              value: res.companyName,
              title: res.companyName
            }
          })
          this.cardTypeList = this.setSelectDefaultVal(list, defaultVal)
          resolve(this.cardTypeList)
        } else {
          reject({ message: "获取投保单位模糊搜索失败", err: res, list: this.setSelectDefaultVal([], defaultVal) })
        }
      }).catch(err => {
        reject({ message: "获取投保单位模糊搜索失败", err: err, list: this.setSelectDefaultVal([], defaultVal) })
      })
    })
  }
  // 登录角色 - 字典类型
  selectUserRoleDic(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      dictionaryModel
        .selectDictDetail({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.userRoleDic = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.userRoleDic);
          } else {
            reject({
              message: "获取登录角色 - 字典类型失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取登录角色 - 字典类型失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }
  //结算主体管理 - 商品库
  selectCommodityLibrary(defaultVal='请选择'){
    return new Promise((resolve, reject) => {
      commonModel.getoperationModule({
        data: {
          headCode: "s_account_company_goods_flag",
          noLoadingRef: 'yes'
        }
      }).then(res => {
        if (res.datas && res.datas.length) {
          let datas = res.datas.slice(0)
          let list = datas.map(res => {
            return {
              id: res.id,
              value: res.dictValue,
              title: res.dictName
            }
          })
          this.commodityLibrary = this.setSelectDefaultVal(list, defaultVal)
          resolve(this.commodityLibrary)
        } else {
          reject({ message: "获取商品库失败", err: res })
        }
      }).catch(err => {
        reject({ message: "获取商品库失败", err: err })
      })
    })
  }

  //字典获取下拉
  selectDictionary(defaultVal = '请选择',code) {
    return new Promise((resolve, reject) => {
      commonModel.getoperationModule({
        data: {
          headCode: code,
          noLoadingRef: 'yes'
        }
      }).then(res => {
        if (res.datas && res.datas.length) {
          let datas = res.datas.slice(0);
          let list = datas.map(res => {
            return {
              id: res.id,
              value: res.dictValue,
              title: res.dictName
            }
          })
          this.dictionaryList = this.setSelectDefaultVal(list, defaultVal);
          resolve(this.dictionaryList)
        } else {
          reject({ message: '获取下拉菜单失败', err: res });
        }
      }).catch(err => {
        reject({ message:  '获取下拉菜单失败', err: err });
      });
    })
  }
  // 订单关系 - 订单状态
  selectOrderStatus(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "o_order_status",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取订单状态失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取订单状态失败", err: err });
        });
    });
  }
}

const globalObj = new ClassGlobal();
export default globalObj;
