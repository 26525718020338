import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Input, Tabs } from "antd";
import { Rules, Storage } from "@/utils";
import "./Login.less";
import loginModel from "@/model/Login/loginModel";
import FormItem from "antd/lib/form/FormItem";
import { withRouter, useHistory } from "react-router-dom";
import { createFromIconfontCN } from "@ant-design/icons";
import { Utils } from "@/utils";
import AnInfoMsg from "@/components/AnInfoMsg";
// 图片
// life
import loginRsBg from "@/assets/lifeBg.jpg";
import beianRS from "@/assets/beianRS.png";
import qrcodeRs from "@/assets/qrcodeRs.jpg";
import telTK from "@/assets/telTK.png";
import telRS from "@/assets/telRS.png";
// tk
import loginTkBg from "@/assets/tkBg.jpg";
const loginLoginTk = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/logoTk.png";
const loginHeaderTk = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerTk.png";
// life
const loginHeaderRs = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerRS.png";
const loginLogoRs = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/logoRS.png";

const { TabPane } = Tabs;
//iconfont
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2335208_ng8pvje3gng.js"
});

const layout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 18
  }
};

const checkLayout = {
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 }
  }
};

const Login = () => {
  const history = useHistory();
  //创建form实例
  const [userForm] = Form.useForm();
  const [InsuranceForm] = Form.useForm();
  const [enterpriseForm] = Form.useForm();
  const [merchantForm] = Form.useForm();
  //校验图片
  const [checkImg, setCheckImg] = useState("");
  const [loading, setLoading] = useState(false);

  const [upgradeMsg, setUpgradeMsg] = useState({
    visible: false,
    type: '',
    onOk: null,
    onCancel: null
  })

  const closeModalUpgradeMsg = useCallback(() => {
    setUpgradeMsg({
      ...upgradeMsg,
      visible: false,
    })
  }, [upgradeMsg])
  const modalUpgradeMsg = useCallback((handleFn = null, type) => {
    setUpgradeMsg({
      visible: true,
      type: type,
      onOk: () => {
        if (typeof handleFn === 'function') {
          handleFn()
        }
      },
      onCancel: () => {
        if (typeof handleFn === 'function') {
          handleFn()
        }
      }
    })
  }, [])

  // 提交form表单
  const onFinish = (type, values) => {
    setLoading(true)
    let insuranceCompanyFlag = Utils.environmentTypeFun() ? 0 : 1;

    console.log(type);
    let options = {
      nickName: values.username,
      password: values.password,
      uuid: checkImg.uuid,
      validateCode: values.code,
      insuranceCompanyFlag: insuranceCompanyFlag
    };
    switch (type) {
      case "User":
        options = { ...options, userType: 6 };
        break;
      case "Insurance":
        options = { ...options, userType: 2 };
        break;
      case "Enterprise":
        options = { ...options, userType: 3 };
        break;
      case "Merchant":
        options = { ...options, userType: 4 };
        modalUpgradeMsg(null, Utils.environmentTypeFun() ? 'TK' : 'LIFE')
        return false
      default:
        return true;
    }
    loginModel
      .userLogin({ data: options })
      .then(({ datas }) => {
        console.log(datas);
        if (datas !== null) {
          Storage.setStorage("userId", datas.userId.toString()); //赋值失败 -- 待解决
          Storage.setStorage("Authorization", `Bearer ${datas.token}`);
          Storage.setStorage('userName', datas.userName);
          Storage.setStorage('easyPasswordFlag', datas.easyPasswordFlag === 1 ? datas.easyPasswordFlag.toString() : ""); // 是否需要修改初始密码
          switch (type) {
            case "User":
              Storage.setStorage("loginType", "6");
              return history.push("/user/home");
            case "Insurance":
              Storage.setStorage("loginType", "2");
              return history.push("/insurance/home");
            case "Enterprise":
              Storage.setStorage("loginType", "3");
              return history.push("/insurance/home");
            // TODO: 商户跳转
            case "Merchant":
              let jumpToken = datas.jumpToken
              if (!jumpToken) {
                Utils.errorMsg({title: '账号登录失败'})
                return false
              }
              return window.open(`${process.env.REACT_APP_QJF_URL}?token=${datas.jumpToken}`)
            default:
              return false;
          }
        }
        setTimeout(() => {
          setLoading(false)
        }, 10)
      })
      .catch(() => {
        getCheckImg();
        setLoading(false)
      });
  };

  //获取校验img
  const getCheckImg = () => {
    loginModel
      .getCheckImg()
      .then(({ datas }) => {
        setCheckImg(datas);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // 修改密码页面
  const onChangePassword = type => {
    history.push("/company-system-updatePassword");
  };

  // 切换tab
  const changeTabs = (tab) => {
    if (tab === '4') {
      modalUpgradeMsg(null, Utils.environmentTypeFun() ? 'TK' : 'LIFE')
    }
  }

  useEffect(() => {
    getCheckImg();
  }, []);

  return (
    <div className="login-module">
      <div className="login-header">
        <img
          className="login-header-img"
          alt="headerImg"
          src={Utils.environmentTypeFun() ? loginHeaderTk : loginHeaderRs}
        />
      </div>
      <div className="login-content">
        <img
          className="login-content-img"
          alt="contentImg"
          src={Utils.environmentTypeFun() ? loginTkBg : loginRsBg}
        />
        <div className="login-box">
          <div className="login-box-banner">
          <div className="login-box-header">
            <img
              className="login-box-header-img"
              alt="loginLogoRs"
              src={Utils.environmentTypeFun() ? loginLoginTk : loginLogoRs}
            />
          </div>
          <Tabs size="large" defaultActiveKey="1" onChange={changeTabs.bind(this)}>
            <TabPane tab="个人" key="1">
              <Form
                style={{position:"relative"}}
                {...layout}
                name="login"
                form={userForm}
                onFinish={onFinish.bind(this, "User")}
              >
                <Form.Item name="username" rules={Rules.ruleCommon("卡号")}>
                  <Input
                    className="loginInput"
                    placeholder="请输入您的卡号"
                    prefix={
                      <IconFont
                        type="icon-user"
                        style={{ fontSize: "26px", color: "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item name="password" rules={Rules.ruleCommon("密码")}>
                  <Input.Password
                    className="loginInput"
                    placeholder="请输入您的密码"
                    //visibilityToggle={false}
                    prefix={
                      <IconFont
                        type="icon-password"
                        style={{ fontSize: "26px", color: "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <div className="tishi">首次登录请输入激活码</div>
                <Form.Item>
                  <FormItem
                    {...checkLayout}
                    name="code"
                    rules={Rules.ruleCommon("验证码")}
                  >
                    <Input
                      className="loginInput"
                      placeholder="验证码"
                      prefix={
                        <IconFont
                          type="icon-shield-full"
                          style={{ fontSize: "26px", color: "gray" }}
                        />
                      }
                    />
                  </FormItem>
                  <img
                    alt="验证码"
                    className="checkImg"
                    src={checkImg.encodedText}
                    onClick={getCheckImg}
                  />
                </Form.Item>
                <div className="login-btn">
                  <Button className="submitButton" htmlType="submit" disabled={loading}>
                    登录
                  </Button>
                </div>
                <div
                  className="forgetPassword"
                  onClick={onChangePassword.bind(this, "User")}
                >
                  忘记登录密码
                </div>
              </Form>
            </TabPane>
            <TabPane tab="保险公司" key="2">
              <Form
                {...layout}
                name="login"
                form={InsuranceForm}
                onFinish={onFinish.bind(this, "Insurance")}
              >
                <Form.Item name="username" rules={Rules.ruleCommon("账号")}>
                  <Input
                    className="loginInput"
                    placeholder="请输入您的账号"
                    prefix={
                      <IconFont
                        type="icon-user"
                        style={{ fontSize: "26px", color: "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item name="password" rules={Rules.ruleCommon("密码")}>
                  <Input.Password
                    className="loginInput"
                    placeholder="请输入您的密码"
                    prefix={
                      <IconFont
                        type="icon-password"
                        style={{ fontSize: "26px", color: "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <FormItem
                    {...checkLayout}
                    name="code"
                    rules={Rules.ruleCommon("验证码")}
                  >
                    <Input
                      className="loginInput"
                      placeholder="验证码"
                      prefix={
                        <IconFont
                          type="icon-shield-full"
                          style={{ fontSize: "26px", color: "gray" }}
                        />
                      }
                    />
                  </FormItem>
                  <img
                    alt="验证码"
                    className="checkImg"
                    src={checkImg.encodedText}
                    onClick={getCheckImg}
                  />
                </Form.Item>
                <div className="login-btn">
                  <Button className="submitButton" htmlType="submit" disabled={loading}>
                    登录
                  </Button>
                </div>
                <div
                  className="forgetPassword"
                  onClick={onChangePassword.bind(this, "User")}
                >
                  忘记登录密码
                </div>
                {/* <p className="forgetPassword"></p> */}
              </Form>
            </TabPane>
            <TabPane tab="企业" key="3">
              <Form
                {...layout}
                name="login"
                form={enterpriseForm}
                onFinish={onFinish.bind(this, "Enterprise")}
              >
                <Form.Item
                  name="username"
                  rules={Rules.ruleCommon("账号")}
                >
                  <Input
                    className="loginInput"
                    placeholder="请输入您的账号"
                    prefix={
                      <IconFont
                        type="icon-user"
                        style={{ fontSize: "26px", color: "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item name="password" rules={Rules.ruleCommon("密码")}>
                  <Input.Password
                    className="loginInput"
                    placeholder="请输入您的密码"
                    prefix={
                      <IconFont
                        type="icon-password"
                        style={{ fontSize: "26px", color: "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <FormItem
                    {...checkLayout}
                    name="code"
                    rules={Rules.ruleCommon("验证码")}
                  >
                    <Input
                      className="loginInput"
                      placeholder="验证码"
                      prefix={
                        <IconFont
                          type="icon-shield-full"
                          style={{ fontSize: "26px", color: "gray" }}
                        />
                      }
                    />
                  </FormItem>
                  <img
                    alt="验证码"
                    className="checkImg"
                    src={checkImg.encodedText}
                    onClick={getCheckImg}
                  />
                </Form.Item>
                <div className="login-btn">
                  <Button
                    className="submitButton"
                    htmlType="submit"
                    size="large"
                    disabled={loading}
                  >
                    登录
                  </Button>
                </div>
                <div
                  className="forgetPassword"
                  onClick={onChangePassword.bind(this, "User")}
                >
                  忘记登录密码
                </div>
                {/* <p className="forgetPassword"></p> */}
              </Form>
            </TabPane>
            {Utils.environmentTypeFun() && (
              <TabPane tab="商户" key="4">
                <Form
                  {...layout}
                  name="login"
                  form={merchantForm}
                  onFinish={onFinish.bind(this, "Merchant")}
                >
                  <Form.Item name="username" rules={Rules.ruleCommon("账号")}>
                    <Input
                      className="loginInput"
                      placeholder="请输入您的账号"
                      prefix={
                        <IconFont
                          type="icon-user"
                          style={{ fontSize: "26px", color: "gray" }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item name="password" rules={Rules.ruleCommon("密码")}>
                    <Input.Password
                      className="loginInput"
                      placeholder="请输入您的密码"
                      prefix={
                        <IconFont
                          type="icon-password"
                          style={{ fontSize: "26px", color: "gray" }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <FormItem
                      {...checkLayout}
                      name="code"
                      rules={Rules.ruleCommon("验证码")}
                    >
                      <Input
                        className="loginInput"
                        placeholder="验证码"
                        prefix={
                          <IconFont
                            type="icon-shield-full"
                            style={{ fontSize: "26px", color: "gray" }}
                          />
                        }
                      />
                    </FormItem>
                    <img
                      alt="验证码"
                      className="checkImg"
                      src={checkImg.encodedText}
                      onClick={getCheckImg}
                    />
                  </Form.Item>
                  <div className="login-btn">
                    <Button
                      className="submitButton"
                      htmlType="submit"
                      size="large"
                      disabled={loading}
                    >
                      登录
                    </Button>
                  </div>
                  <div
                  className="forgetPassword"
                  onClick={onChangePassword.bind(this, "User")}
                >
                  忘记登录密码
                </div>
                  {/* <p className="forgetPassword"></p> */}
                </Form>
              </TabPane>
            )}
          </Tabs>
          </div>
        </div>
      </div>
      <div className="login-footer">
        <div className="footer-content">
          <img className="footer-img-qrcode" alt="qrcodeRs" src={qrcodeRs} />
          <div className="footer-content-text">
            <p>本业务由企健网提供服务</p>
            <p>中华人民共和国工业与信息产业部备案号：沪ICP备18034726号-2</p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602003511"
                className="beian"
              >
                <img alt="beianRS" src={beianRS} />
                沪公网安备 31010602003511号
              </a>
            </p>
            <p>系统将在以下浏览器内核下完美运行：IE10+、谷歌、火狐</p>
          </div>
        </div>
        <div className="footer-tel">
          <img className="footer-img-tel" alt="telRS" src={Utils.environmentTypeFun() ? telTK : telRS} />
        </div>
      </div>
      {/* 弹窗 - 公告  */}
      <AnInfoMsg  visible={upgradeMsg.visible}
        wrapClassName="upgrade-msg__container"
        width="600px"
        title="网址变更通知"
        // onOk={upgradeMsg.onOk}
        onCancel={closeModalUpgradeMsg}
        btns={[{ text: '我知道了', handleClick: closeModalUpgradeMsg.bind(this) }]}
      >
         {upgradeMsg.type === 'TK' && <div className="clearflex">
          <p>尊敬的各位用户：</p>
          <p>因系统升级， 老网址 tk.qijian365.com  商户入口已关闭，<span className="red-b">请您使用新网址：pay.qijian99.com 登录企健付平台下单</span>，请收藏新网址方便下次登录。特此通知</p>
          {/* <p>因系统升级，即日起，<span className="red-b">请您使用新网址：pay.qijian99.com登录企健付平台下单。</span></p>
          <p>如果您在浏览器中无法访问新网址：pay.qijian99.com，请联系贵司技术添加新网址及IP白名单，新IP地址：49.233.194.120。</p>
          <p><span className="red-b">系统升级只是网址变更，其他不变。请使用您门店的账号密码登录新网址，正常刷卡。</span></p>
          <p>给您带来不便敬请谅解，感谢您的支持配合！</p> */}
        </div>}
      </AnInfoMsg>
    </div>
  );
};

export default withRouter(Login);
