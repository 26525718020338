import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//引入antd
import { Form, Input, Modal, Row, Col, Button } from "antd";
//引入Utils公共组件
import { Rules, Utils, OPTIONS_FORM_SEARCH } from "@/utils";
import loginModel from "@/model/Login/loginModel";
import UserHomeModel from "@/model/UserHome/UserHomeModel";

import "./ChangePassword.less";
const ChangePassword = props => {
  const history = useHistory();
  const [form] = Form.useForm();
  //校验图片
  const [checkImg, setCheckImg] = useState("");
  const [countDownStatus, setCountDownStatus] = useState(true);
  const [time, setTime] = useState(59);

  // //获取校验img
  const getCheckImg = () => {
    loginModel
      .getCheckImg()
      .then(({ datas }) => {
        setCheckImg(datas);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSMS = () => {
    if (!form.getFieldValue().code || form.getFieldValue().code === "")
      return Utils.warnMsg({ title: "请填写图片验证码！" });
    if (
      !form.getFieldValue().mobilePhone ||
      form.getFieldValue().mobilePhone === ""
    )
      return Utils.warnMsg({ title: "请填写手机号！" });

    let options_checkInfo = {
      medcardNo: form.getFieldValue().medcardNo,
      mobilePhone: form.getFieldValue().mobilePhone
    };
    // 校验手机号 卡号相符
    UserHomeModel.sendSmsCheckInfo({ data: options_checkInfo })
      .then(res => {
        if (res.message === "success") {
          if (countDownStatus) {
            // setCountDownStatus(false);
            let options = {
              imgCaptchaCode: form.getFieldValue().code,
              imgUuid: checkImg.uuid,
              insuranceCompanyFlag: Utils.environmentTypeFun() ? 0 : 1,
              mobile: form.getFieldValue().mobilePhone
            };
            loginModel
              .sendSmsCaptcha({ data: options })
              .then(res => {
                if (res.success === true) {
                  setCountDownStatus(false);
                  let active = setInterval(() => {
                    setTime(preSecond => {
                      if (preSecond <= 0) {
                        setCountDownStatus(true);
                        clearInterval(active);
                        // 重置秒数
                        return 59;
                      }
                      return preSecond - 1;
                    });
                  }, 1000);
                }
              })
              .catch(err => getCheckImg());
          }
        }
      })
      .catch(err => {
        getCheckImg();
        console.log(err);
      });

  };
  const handleReset = () => {
    form.resetFields();
  };
  const getFormData = () => {
    let options = form.getFieldsValue();
    console.log(options);
    if (options.loginPassword !== options.confirmLoginPassword) {
      Utils.errorMsg({ title: "两次输入密码不一致，请重新输入！" });
      return false;
    }
    UserHomeModel.forgetLoginPassword({ data: options })
      .then(res => {
        if (res.message === "success") {
          Modal.success({
            title: "修改成功",
            content: "请尝试再次登录。",
            okText: "确认",
            onOk: () => {
              history.replace("/");
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCheckImg();
  }, []);

  return (
    <div className="card-status">
      <div className="card-statu-title">忘记登录密码</div>
      <div className="card-activation">
        <Form
          name="statistical-card-consumption"
          form={form}
          {...OPTIONS_FORM_SEARCH}
          onFinish={getFormData}
        >
          <Row align="middle" justify="center" gutter={24}>
            <Col span={16}>
              <Form.Item
                label="卡号"
                className="input-item"
                name="medcardNo"
                rules={Rules.ruleCommon("卡号")}
              >
                <Input type="text" placeholder="请输入卡号" />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center" gutter={24}>
            <Col span={16}>
              <Form.Item
                label="手机号"
                className="input-item"
                name="mobilePhone"
                rules={Rules.ruleCommon("手机号")}
              >
                <Input type="text" placeholder="请输入手机号" />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center" gutter={24}>
            <Col span={16}>
              <Form.Item label="验证码" className="flex-item">
                <Form.Item
                  name="code"
                  className="flex-item-input"
                  rules={Rules.ruleCommon("图片验证码")}
                >
                  <Input type="text" placeholder="请输入图片验证码" />
                </Form.Item>
                <div className="checkImg">
                  <img
                    alt="验证码"
                    className="Img"
                    src={checkImg.encodedText}
                    onClick={getCheckImg}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center" gutter={24}>
            <Col span={16}>
              <Form.Item label="短信验证码" className="flex-item">
                <Form.Item
                  name="smsCaptchaCode"
                  className="flex-item-input"
                  rules={Rules.ruleCommon("短信验证码")}
                >
                  <Input placeholder="请输入短信验证码" />
                </Form.Item>
                <Button className="yzBtn" type="primary" onClick={getSMS}>
                  {countDownStatus ? "获取验证码" : `${time}秒`}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center" gutter={24}>
            <Col span={16}>
              <Form.Item
                label="密码"
                className="input-item"
                name="loginPassword"
                rules={Rules.ruleUserPassword(
                  "6~18位字母+数字的组合，字母区分大小写"
                )}
              >
                <Input.Password placeholder="6~18位字母+数字的组合，字母区分大小写" />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center" gutter={24}>
            <Col span={16}>
              <Form.Item
                label="确认密码"
                className="input-item"
                name="confirmLoginPassword"
                rules={Rules.ruleUserPassword(
                  "6~18位字母+数字的组合，字母区分大小写"
                )}
              >
                <Input.Password placeholder="再次输入新登录密码" />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" justify="center" gutter={24}>
            <Col span={11}>
              <Button
                className="password-btn"
                style={{ margin: "0 8px" }}
                onClick={handleReset}
              >
                重置
              </Button>
              <Button className="password-btn" type="primary" htmlType="submit">
                确定
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
