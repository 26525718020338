import  { lazy } from 'react'

import { BasicLayout, UserLayout, NoFound, Forbidden, Login, ChangePassword, BindingPhone } from '@/app/Layout/index'


// Insurance 模塊
const InsuranceHome = lazy(() => import('@/pages/Insurance/Home/Home'))
const AnnouncementDetails = lazy(() => import('@/pages/Insurance/Home/AnnouncementDetails'))

// 卡号管理
const CardManage = lazy(() => import('@/pages/Insurance/CardManage'))
const CardNumQuery = lazy(() => import('@/pages/Insurance/CardManage/CardNumQuery')) // 卡号管理 - 卡号查询
const CardOperatingLog = lazy(() => import('@/pages/Insurance/CardManage/CardOperatingLog')) // 卡号管理 - 卡操作日志

// 理赔管理
const ClaimsManage = lazy(() => import('@/pages/Insurance/ClaimsManage'))
const ClaimsList = lazy(() => import('@/pages/Insurance/ClaimsManage/ClaimsList')) // 理赔管理 - 理赔列表
const ClaimsMonitoringLog = lazy(() => import('@/pages/Insurance/ClaimsManage/ClaimsMonitoringLog')) // 理赔管理 - 理赔操作日志
const BatchClaims = lazy(() => import('@/pages/Insurance/ClaimsManage/BatchClaims')) // 理赔管理 - 批量理赔管理

// 统计查询
const StatisticalManage = lazy(() => import('@/pages/Insurance/StatisticalManage'))
const StatisticalOpenCard =  lazy(() => import('@/pages/Insurance/StatisticalManage/StatisticalOpenCard')) // 统计查询 - 开卡统计
const StatisticalCardConsumption =  lazy(() => import('@/pages/Insurance/StatisticalManage/StatisticalCardConsumption')) // 统计查询 - 卡消费统计
const StatisticalInsuredUnitConsumption =  lazy(() => import('@/pages/Insurance/StatisticalManage/StatisticalInsuredUnitConsumption')) // 统计查询 - 投保单位消费统计
const StatisticalDayConsumption =  lazy(() => import('@/pages/Insurance/StatisticalManage/StatisticalDayConsumption')) // 统计查询 - 日消费统计

// 团单管理
const GroupOrderManage = lazy(() => import('@/pages/Insurance/GroupOrderManage'))
const GroupOrderSrategyQuery = lazy(() => import('@/pages/Insurance/GroupOrderManage/GroupOrderSrategyQuery')) // 团单管理 - 团单策略查询

// 订单管理
const OrderMange = lazy(() => import('@/pages/Insurance/OrderMange'))
const OrderQuery = lazy(() => import('@/pages/Insurance/OrderMange/OrderQuery')) // 订单管理 - 订单查询

// 系统设置
const SystemSetting = lazy(() => import('@/pages/Insurance/SystemSetting'))
const InsuranceChangePassword = lazy(() => import('@/pages/Insurance/SystemSetting/ChangePassword')) // 系统设置 - 修改密码

// 财务对账文件
const ReconciliationManage =  lazy(() => import('@/pages/Insurance/ReconciliationManage'))
const ReconciliationFundSettlement =  lazy(() => import('@/pages/Insurance/ReconciliationManage/ReconciliationFundSettlement')) // 财务对账文件 - 资金方结算列表
const ReconciliationUnifiedSettlement =  lazy(() => import('@/pages/Insurance/ReconciliationManage/ReconciliationUnifiedSettlement')) // 财务对账文件 - 统一结算列表

// 本地账户管理
const AccountManage = lazy(() => import('@/pages/Insurance/AccountManage'))
const AccountLocalQuery = lazy(() => import('@/pages/Insurance/AccountManage/AccountLocalQuery')) // 订单管理 - 订单查询

// user routes
// home
const Home = lazy(() => import('@/pages/User/Home/Home'))         // 主页
const Message  = lazy(() => import('@/pages/User/Home/Message'))  // 站内信系
const TransactionDetails  = lazy(() => import('@/pages/User/Home/TransactionDetails'))  // 消费明细
const NetworkDetails  = lazy(() => import('@/pages/User/Home/NetworkDetails'))  // 服务网络

// 卡片信息
const CardInfo = lazy(() => import('@/pages/User/CardInfo'))
const CardStatus = lazy(() => import('@/pages/User/CardInfo/CardStatus')) // 卡片信息 - 状态
const CardFreeze = lazy(() => import('@/pages/User/CardInfo/CardFreeze')) // 卡片信息 - 冻结
const CardActivation = lazy(() => import('@/pages/User/CardInfo/CardActivation')) // 卡片信息 - 激活

// 修改密码
const EditPassword = lazy(() => import('@/pages/User/EditPassword')) // 修改密码
const ResetTransactionPassword = lazy(() => import('@/pages/User/EditPassword/ResetTransactionPassword')) // 修改支付密码
const ResetLoginPassword = lazy(() => import('@/pages/User/EditPassword/ResetLoginPassword')) // 修改登录密码
const ResetPhone = lazy(() => import('@/pages/User/EditPassword/ResetPhone')) // 修改手机号

const XMLHandle = lazy(()=>import('@/pages/Insurance/XMLHandle'))   //xml回传处理
const XmlInfoReview = lazy(()=>import('@/pages/Insurance/XMLHandle/XmlInfoReview'))//xml回传处理-数据复核
const XmlInfoReviewImg = lazy(()=>import('@/pages/Insurance/XMLHandle/XmlInfoReviewImg'))//数据复核-影像件

const QhealthTPA = lazy(()=>import('@/pages/Insurance/QhealthTPA'))   //企健TPA
const ReviewTPA = lazy(()=>import('@/pages/Insurance/QhealthTPA/ReviewTPA'))//企健TPA-复核
const EnterTPAImg = lazy(()=>import('@/pages/Insurance/QhealthTPA/EnterTPAImg'))//企健TPA-录入-查看发票图片

const routes = [
  {
    path: '/',
    exact: true,
    component: Login
  },
  {
    path: '/login',
    component: Login
  },
  {
    path:'/company-system-updatePassword',
    component: ChangePassword
  },
  {
    path:'/binding-phone',
    component: BindingPhone
  },
  {
    path: '/404',
    component: NoFound
  },
  {
    path: '/403',
    component: Forbidden
  },
  {
    path:'/user',
    component: UserLayout,
    routes: [
      {
        path: "/user/home",
        component: Home,
      },
      {
        path: "/user/message",
        component: Message,
      },
      {
        path: "/user/transaction-details",
        component: TransactionDetails,
      },
      {
        path: "/user/networl-details",
        component: NetworkDetails,
      },
      {
        path: "/user/card-info",
        component: CardInfo,
        routes: [
          {
            path: "/user/card-info/card-status",
            component: CardStatus // 状态
          },
          {
            path: "/user/card-info/card-freeze",
            component: CardFreeze // 冻结
          },
          {
            path: "/user/card-info/card-activation",
            component: CardActivation // 激活
          }

        ]
      },
      {
        path: "/user/edit-password",
        component: EditPassword,
        routes: [
          {
            path: "/user/edit-password/reset-transaction-password",
            component: ResetTransactionPassword // 修改支付密码
          },
          {
            path: "/user/edit-password/reset-login-password",
            component: ResetLoginPassword // 修改登录密码
          },
          {
            path: "/user/edit-password/reset-phone",
            component: ResetPhone // 修改手机号
          },
        ]
      },
    ]
  },
  {
    path:'/insurance',
    component: BasicLayout,
    routes: [
      {
        path: "/insurance/home",
        component: InsuranceHome
      },
      {
        path: "/insurance/announcement-details",
        component: AnnouncementDetails
      },
      {
        path: "/insurance/card-manage",
        component: CardManage,
        routes: [
          {
            path: "/insurance/card-manage/card-cardQuery",
            component: CardNumQuery // 卡号查询
          },
          {
            path: "/insurance/card-manage/card-log",
            component: CardOperatingLog // 卡操作日志
          }
        ]
      },
      {
        path: "/insurance/claims-center",
        component: ClaimsManage,
        routes: [
          {
            path: "/insurance/claims-center/claim-claim",
            component: ClaimsList //  理赔列表
          },
          {
            path: "/insurance/claims-center/claim-log",
            component: ClaimsMonitoringLog // 理赔监控日志
          },
          {
            path: "/insurance/claims-center/claim-batchClaim",
            component: BatchClaims // 批量理赔管理
          }
        ]
      },
      {
        path: "/insurance/group-order-manage",
        component: GroupOrderManage,
        routes: [
          {
            path: "/insurance/group-order-manage/groupPolicy-groupPolicyStrategy",
            component: GroupOrderSrategyQuery  // 团单策略查询
          }
        ]
      },
      {
        path: "/insurance/statistical-query",
        component: StatisticalManage,
        routes: [
          {
            path: "/insurance/statistical-query/statistics-openCardCount",
            component: StatisticalOpenCard  // 开卡统计
          },
          {
            path: "/insurance/statistical-query/statistics-cardSaleCount",
            component: StatisticalCardConsumption  // 卡消费统计
          },
          {
            path: "/insurance/statistical-query/statistics-companySaleCount",
            component: StatisticalInsuredUnitConsumption  // 投保单位消费统计
          },
          {
            path: "/insurance/statistical-query/statistics-daySaleCount",
            component: StatisticalDayConsumption  // 日消费统计
          }
        ]
      },
      {
        path: "/insurance/order-manage",
        component: OrderMange,
        routes: [
          {
            path: "/insurance/order-manage/order-orderQuery",
            component: OrderQuery  // 修改密码
          }
        ]
      },
      {
        path: "/insurance/company-system",
        component: SystemSetting,
        routes: [
          {
            path: "/insurance/company-system/company-system-updatePassword",
            component: InsuranceChangePassword  // 修改密码
          }
        ]
      },
      {
        path: "/insurance/company-account",
        component: ReconciliationManage,
        routes: [
          {
            path: "/insurance/company-account/company-account-insuranceCompany",
            component: ReconciliationFundSettlement  // 资金方结算列表
          },
          {
            path: "/insurance/company-account/company-account-qjPayment",
            component: ReconciliationUnifiedSettlement  // 统一结算列表
          }
        ]
      },
      {
        path: "/insurance/account-manage",
        component: AccountManage,
        routes: [
          {
            path: "/insurance/account-manage/account-localQuery",
            component: AccountLocalQuery  // 本地账户查询
          }
        ]
      },
      {
        path:"/insurance/company-xmlInfo-tpa",
        component: XMLHandle,
        routes: [
          {
            path:"/insurance/company-xmlInfo-tpa/company-xmlInfo-data-review",
            component: XmlInfoReview
          },
          {
            path:"/insurance/company-xmlInfo-tpa/company-xmlInfo-data-review-img",
            component: XmlInfoReviewImg
          },
        ]
      },
      {
        path:"/insurance/qhealth-infoEnter-tpa",
        component: QhealthTPA,
        routes: [
          {
            path:"/insurance/qhealth-infoEnter-tpa/qhealth-review-tpa",
            component: ReviewTPA
          },
          {
            path:"/insurance/qhealth-infoEnter-tpa/qhealth-enter-img",
            component: EnterTPAImg
          },
        ]
      },
      {
        path: '*',
        component: NoFound
      }
    ]
  },
  {
    path: '*',
    component: NoFound
  },
];

export default routes
